import React, { useState } from 'react';
import {
    ChatBubble,
    ChatBubbleContainer,
    formatDate,
    InfiniteList,
    MessageAttachment
} from 'amazon-chime-sdk-component-library-react';

import {
    listChannelMessages,
    createMemberArn
} from '../../../../api/ChimeAPI';
import './PublicMessages.css';
import { useAppDispatch } from '../../../../redux/store';
import { ChannelMessagesState, ChannelSetMessage, MessageState, SetIsTranslate, selectIsTranslate } from '../../../../redux/chimeSlide';
import { AttachmentService } from '../../../../services/AttachmentService';
import { liveTranslateMessage } from '../../../../helpers/api';
import { useTranslation } from 'react-i18next';
import insertDateHeadersPublic from '../../../../utilities/insertDateHeadersPublic';
import { useSelector } from 'react-redux';
import { CommonService } from '../../../../services/Common';
import Attachment from '../PublicInputChat/Attachment';
import i18n from '../../../../translations/i18n';
import { senderNameType } from '../../../../redux/assistantBot';
import formatTime from '../../../../services/FormatTime';

interface MessagesProps {
    messages: MessageState,
    channelArn?: string,
    userId?: string,
    // isTranslate: boolean,
    translateModeData: any,
    uuid: string
}

const PublicMessages = ({ messages, channelArn, userId, translateModeData, uuid }: MessagesProps) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const isTranslate = useSelector(selectIsTranslate);
    const dispatchReduxToolkit = useAppDispatch();
    var isLoadImg = false;
    const browserLanguage = i18n.language;

    const resize = (size: string) => {
        let size_num = Number(size)
        return size_num < 1024 ? `${size} bytes` : size_num < 1024 * 1024 ? `${String((size_num / 1024).toFixed(2))} KB` : `${String((size_num / 1024 / 1024).toFixed(2))} MB`
    }

    const onTranslate = async (message: string) => {
        if (isTranslate.status) {
            dispatchReduxToolkit(SetIsTranslate({
                status: false,
                langSource: "auto",
                langTarget: "auto",
                languagebox: false
            }));
        } else {
            let messageDetectLanguage = await CommonService.detectLanguage({ text: message });
            dispatchReduxToolkit(SetIsTranslate({
                status: true,
                langSource: messageDetectLanguage,
                langTarget: browserLanguage,
                languagebox: true
            }));
        }

    }

    const handleScrollTop = async () => {

        if (isLoadImg) {
            isLoadImg = false;
            return;
        }

        setIsLoading(true);
        if (!messages?.NextToken) {
            setIsLoading(false);
            return;
        }
        const oldMessages = await listChannelMessages(
            channelArn,
            userId,
            messages.NextToken
        );

        const message_translate = await Promise.all(oldMessages.Messages.map(async (message: any) => {
            if (createMemberArn(userId) !== message.Sender.Arn && translateModeData.isTranslate) {
                // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
                const translateMessage = await liveTranslateMessage(message.Content, translateModeData.langSource, translateModeData.langTarget);
                return { ...message, Content: translateMessage }
            }
            return message;
        }));

        const newMessages = [...message_translate, ...messages?.ChannelMessages ? messages.ChannelMessages : []];
        dispatchReduxToolkit(ChannelSetMessage({ ChannelMessages: newMessages, NextToken: oldMessages.NextToken }));
        setIsLoading(false);
    };




    let lastSeen = -1;
    let lastSent = -1;

    const flattenedMessages = messages.ChannelMessages.map((m: ChannelMessagesState, index: number) => {

        const content = !m.Content || m.Redacted ? '(Deleted)' : m.Content;
        let editedNote;
        if (m.LastEditedTimestamp && !m.Redacted) {
            const time = formatTime(m.LastEditedTimestamp);
            const date = formatDate(
                m.LastEditedTimestamp,
                undefined,
                undefined,
                'today',
                'yesterday'
            );
            editedNote = (
                <i style={{ fontStyle: 'italic' }}>{` (edited ${date} at ${time})`}</i>
            );
        }

        const messageStatus = m.Status.Value == null ? 'SENT' : m.Status.Value;
        let statusNote;
        if (messageStatus !== 'SENT') {
            statusNote = (
                <i style={{ fontStyle: 'italic' }}>{`     (${messageStatus})`}</i>
            );
        } else {
            lastSent = index;
        }

        if (m.Metadata && m.Sender.Arn === createMemberArn(userId)) {
            lastSeen = JSON.parse(m.Metadata)?.status === "Read" ? index : lastSeen;
        }

        return {
            content: content,
            editedNote: editedNote,
            messageId: m.MessageId,
            createdTimestamp: m.CreatedTimestamp,
            redacted: m.Redacted,
            senderName: m.Sender.Name,
            senderId: m.Sender.Arn,
            metadata: m.Metadata,
            status: m.Status.Value,
            statusNote: statusNote
        };
    });

    const attachmentService = AttachmentService.getInstance();

    const onloadimg = () => {
        isLoadImg = true;
    }

    const detectSenderName = (message: any) => {
        let technician = senderNameType.technician;
        if (message.senderId.includes(technician)) {
            return message.senderName
        }

        return "Bosch Expert"
    }

    const listItems = insertDateHeadersPublic(flattenedMessages);

    const messageList = listItems.map((m, i, self) => {
        if (!m.content) {
            return m;
        }

        let hasAttachment = -1;

        let metadata: {
            isMeetingInfo: boolean,
            attachment: {
                fileKey: string,
                type: string,
                size: string,
                name: string,
                requestType?: string;
            },
            status: string,
            meeting: {
                meetingId: string,
                pathRoute: string
            },
            onStartVoiceCall: boolean
        } = {
            isMeetingInfo: false,
            attachment: {
                fileKey: '',
                type: '',
                size: '',
                name: ''
            },
            status: '',
            meeting: {
                meetingId: '',
                pathRoute: ''
            },
            onStartVoiceCall: false
        };

        let preSignURL;

        if (m.metadata) {
            metadata = JSON.parse(m.metadata);
            if (metadata.isMeetingInfo) {
                return m;
            };

            if (metadata?.attachment?.fileKey) {
                preSignURL = attachmentService.getPresignURL(metadata.attachment.fileKey);
                if (metadata.attachment?.type.includes('image')) {
                    hasAttachment = 0;
                } else {
                    hasAttachment = 1;
                }
            }
        }

        const variant = createMemberArn(userId) === m.senderId ? 'outgoing' : 'incoming';

        const prevMessage = self[i - 1];
        const nextMessage = self[i + 1];
        // if (!m.content) {
        //     nextMessage = self[i + 2];
        // }

        let statusMessageShow = true;
        let showName = true;
        let createdTimestamp = null;
        let senderName = detectSenderName(m);
        if (m.senderId && prevMessage?.senderId && m.senderId === prevMessage?.senderId) {
            showName = false;
        }
        createdTimestamp = formatTime(m.createdTimestamp);

        if (m.senderId && nextMessage?.senderId && m.senderId === nextMessage?.senderId) {
            try {

                if (nextMessage.status === m.status) {
                    statusMessageShow = false;
                }
                //TODO:
                // if (nextMessage.metadata != m.metadata) {
                //     statusMessageShow = true;
                // }

                if (JSON.parse(nextMessage.metadata || { status: "" }).status != metadata.status) {
                    statusMessageShow = true;
                }

                if ((Math.abs(nextMessage.createdTimestamp.getTime() - m.createdTimestamp.getTime()) / 1000 / 60) > 1) {
                    createdTimestamp = formatTime(m.createdTimestamp);
                } else {
                    createdTimestamp = null;
                }
            } catch {
                createdTimestamp = null;
            }
        }

        if (metadata.status !== "" && i < (lastSeen + (self.length - flattenedMessages.length) - 1)) {
            statusMessageShow = false;
        }
        if (metadata.status == "" && (i <= (lastSent + (self.length - flattenedMessages.length) - 1) || i <= (lastSeen + (self.length - flattenedMessages.length) - 1))) {
            statusMessageShow = false;
        }

        if (variant === 'outgoing' && statusMessageShow)

            if (metadata.status) {
                createdTimestamp = createdTimestamp ? createdTimestamp + `(${t('Read_panel')})` : `(${t('Read_panel')})`;
            } else {
                createdTimestamp = createdTimestamp ? createdTimestamp + `(${t('Sent_panel')})` : `(${t('Sent_panel')})`;
            }

        return (
            <div className="message">

                {variant === 'outgoing' ? <>
                    <ChatBubbleContainer
                        className='chat-bubble-container-mine'
                        timestamp={createdTimestamp}
                        key={`message${i.toString()}`}>

                        <ChatBubble
                            className='chat-bubble-mine'
                            variant={variant}>
                            {hasAttachment !== -1 &&
                                <div style={{ display: 'flex' }}>
                                    {hasAttachment === 0 &&
                                        <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`} />
                                    }
                                    {hasAttachment === 1 &&
                                        <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} isScanResult={metadata?.attachment?.requestType ? true : false} />
                                    }
                                </div>
                            }
                            {m.content}
                            {metadata?.meeting?.meetingId && <a style={{ color: 'white', cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting?.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId + "_" + uuid}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}> {t('Link_label')}</a>}

                        </ChatBubble>
                    </ChatBubbleContainer>
                </> :
                    <ChatBubbleContainer
                        className='chat-bubble-container-them'
                        key={`message${i.toString()}`}>
                        {showName ?
                            <>
                                <ChatBubble
                                    className='chat-bubble-them'
                                    variant={variant}
                                    senderName={senderName}>
                                    {hasAttachment !== -1 &&
                                        <div style={{ display: 'flex' }}>
                                            {hasAttachment === 0 &&
                                                // <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={''} imgOnClick={() => handleAttachmentClick(`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`)} />
                                                <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={true} isScanResult={metadata?.attachment?.requestType ? true : false} />
                                            }
                                            {hasAttachment === 1 &&
                                                <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} isScanResult={metadata?.attachment?.requestType ? true : false} />
                                            }
                                        </div>
                                    }
                                    <div>
                                        {m.content}
                                        {metadata?.meeting?.meetingId && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId + "_" + uuid}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}>{t('Link_label')}</a>}
                                        {
                                            <>
                                                <br /><br />
                                                <a style={{ cursor: 'pointer', textDecorationLine: 'none', marginTop: '5px' }} target={"_blank"} onClick={() => onTranslate(m.content)}>{isTranslate.status ? t('Dis_translation') : t('En_translation')}</a>
                                            </>
                                        }
                                    </div>
                                </ChatBubble>
                                <div className='message-time'>{createdTimestamp}</div>
                            </>

                            :

                            <>
                                <ChatBubble
                                    className='chat-bubble-them'
                                    variant={variant}>
                                    <div>
                                        {hasAttachment !== -1 &&
                                            <div style={{ display: 'flex' }}>
                                                {hasAttachment === 0 &&
                                                    <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={true} isScanResult={metadata?.attachment?.requestType ? true : false} />
                                                }
                                                {hasAttachment === 1 &&
                                                    <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} isScanResult={metadata?.attachment?.requestType ? true : false} />
                                                }
                                            </div>
                                        }
                                        {m.content}
                                        {metadata?.meeting?.meetingId && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId + "_" + uuid}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}>{t('Link_label')}</a>}
                                        {<>
                                            <br /><br />
                                            <a style={{ cursor: 'pointer', textDecorationLine: 'none', marginTop: '5px' }} target={"_blank"} onClick={() => onTranslate(m.content)}>{isTranslate.status ? t('Dis_translation') : t('En_translation')}</a>
                                        </>
                                        }
                                    </div>
                                </ChatBubble>
                                <div className='message-time'>{createdTimestamp}</div>
                            </>

                        }
                    </ChatBubbleContainer>
                }

            </div>
        );
    });

    return (
        <div className="public-message-list-container">
            {(messages) &&
                <InfiniteList
                    style={{ overflow: 'hidden', backgroundColor: '#fff' }}
                    items={messageList}
                    onLoad={handleScrollTop}
                    isLoading={isLoading}
                />
            }
        </div>
    );
};
export default PublicMessages;