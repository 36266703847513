export const TRANSLATIONS_DA = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Opret ny",
	Start_New_Question: "Hvordan vil du oprette en ny Private Channel?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kode",
	Channel_List: "Kanalliste",
	Chat_Label: "Chat",
	Call_Label: "Opkald",
	Video_Label: "Video",
	Share_Label: "Del",
	Stop_Channel_Label: "Stop kanal",
	Contact_Details_Label: "Kontaktdetaljer",
	Request_Details_Label: "Ticket detaljer",
	Attendees_Label: "Deltagere",
	Customer_Label: "Kunde",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Indtast din besked",
	Stop_Channel_Question: "Ønsker du at lukke kanalen?",
	Yes_Label: "Jan",
	Cancel_Label: "Afbryd",
	Start_New_Video_Session: "Opret videoopkald",
	Without_SMS_Label: "Uden SMS",
	Send_SMS_Label: "Send SMS",
	Send_Invite_Label: "Send invitation",
	Log_Out_Question: "Ønsker du at logge ud?",
	Welcome_Label: "Velkommen til Private Channel Solution",
	Login_Label: "Log ind",
	Login_Is_Required_Label: "Log ind er påkrævet for at oprette en kanal",
	Account_Permission_Label: "Denne konto har ikke de nødvendige rettigheder for at oprette en kanal",
	Start_Label: "Start",
	Noted_Recommend: "På grund af Boschs politik for brug af Firefox anbefaler vi, at du bruger en anden browser for at få den bedste brugeroplevelse af Private Channel Solution-applikationen. Anbefalede browsere: Microsoft Edge og Google Chrome",
	Available_Languages_Label: "Tilgængelige sprog",
	Can_Not_Create_Meeting_Title: "Kunne ikke oprette en ny privat kanal",
	Can_Not_Create_Meeting_Content: "Du er stadig i en aktiv privat kanal. Afslut venligst den aktuelle kanal, før du starter en ny.",
	Leave_meeting_Warning: "Forlad den private kanal, eller du kan afslutte kanalen for alle. Kanalen kan ikke bruges, når den først slutter.",
	Leave_Lable: "Forlad",
	End_Lable: "Afslut",
	Meeting_Ended: "Mødet er afsluttet",
	Start_Channel_Session: "Start kanal",
	Start_Channel_Session_Content: "Teknikeren er i øjeblikket i gang med ticket ID:",
	Join_Lable: "Deltag",
	Join_Meeting_Label: "Deltag i kanal",
	Meeting_Information_Label: "Kanal information",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Oversættelsesmuligheder",
	Translate_Mode_Label: "Oversættelse:",
	From_Label: "Fra:",
	To_Label: "Til:",
	Chat_Room_Label: "Privat Kanal",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Hvordan vil du gerne dele invitationen til kanalen?",
	Invite_To_Visual_Question: "Hvordan vil du gerne dele Visual Connect-invitationen?",
	Invite_Sms_Question: "Send invitation på SMS",
	Invite_To_Chat_By_Ticket_ID: "Send invitation med Ticket ID",
	Invite_Email_Question: "Send invitation via e-mail",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applikationer",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Besøg hjemmesiden",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "Generelle kontaktoplysninger",
	WSA_PSIRT_label: "Produktsikkerhed (PSIRT)",
	WSA_licences_label: "Innovationer, patenter og licenser",
	WSA_purchasing_label: "Indkøb og logistik",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	WSA_copyright_information_label: "Virksomhedsoplysninger",
	WSA_copyright_notice_label: "Juridisk meddelelse",
	WSA_copyright_protectionnotice_label: "Databeskyttelsesmeddelelse",
	WSA_copyright_terms_label: "Vilkår og Betingelser",
	WSA_copyright_settings_label: "Privatindstillinger",
	Infor_CorporateInformation_Description: "Ansvarlig for Robert Bosch GmbH's internetsider",
	Infor_DataProtectionNotice_Description: "Databeskyttelsesmeddelelse for Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "For brugere af Robert Bosch GmbH's internetsider",
	Id_panel: "Id",
	Topic_panel: "Emne",
	Make_panel: "Mærke",
	Model_pane: "Model",
	Engine_code_panel: "Motorkode",
	Year_panel: "Årgang",
	Vin_panel: "Stelnr.",
	Plate_panel: "Nr.plade",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Din enhed understøtter ikke Augmented Reality",
	Write_something: "Skriv noget for at begynde at chatte",
	Session_title: "Kanal",
	Live_Translation: "Oversæt",
	Enabled_panel: "Aktiveret",
	Joined_note: "Du deltager nu i kanalen…",
	Today_panel: "Idag",
	Read_panel: "Læst",
	Sent_panel: "Sendt",
	Dis_translation: "Deaktiver oversættelse",
	En_translation: "Oversæt",
	Terminated_Channel_note: "Din Bosch Ekspert har lukket denne kanal!",
	Not_start_note: "er ikke startet endnu!",
	Retry_note: "Klik på \"Prøv igen\" for at prøve igen, eller luk dette vindue for at afslutte.",
	Retry_panel: "Prøv igen",
	Thanks_note: "Tak, fordi du bruger Bosch teknisk support. Jeg er meget glad for, at vi havde muligheden for at hjælpe dig i dag. Kontakt os venligst igen, hvis du har brug for hjælp. Hav en god dag.",
	Continue_panel: "Fortsæt",
	En_live_translate: "Aktiver oversættelse",
	Translate_from: "Oversæt fra",
	Translate_to: "Oversæt til",
	Random_ID: "Random ID",
	Anonymous: "Anonym",
	Generated_ID: "Random ID er blevet genereret!",
	Your_ID: "Dit \"Random ID\" er:",
	Public_Meeting_ID_Label: "Møde ID:",
	SelectContactPersonModal_Title: "Hvem vil du gerne chatte med?",
	SelectContactPersonDropDown_Title: "Kontaktperson",
	SelectContactPersonDropDown_Error_Message: "Denne bruger har ikke et ciam-id (brugerkonto til CDM), Vælg en anden kontaktperson for at fortsætte ...",
	TicketCreator__Title: "(forespørgselsskaber)",
	Wsa_status_title: "WSA status",
	Close_panel: "Luk",
	OCR_Detected_title: "Optisk tegngenkendelse registreret:",
	Voice_Call_Label: "Stemmeopkald",
	Invite_To_Voice_Call_Question: "Hvordan vil du dele invitationen til taleopkald?",
	AR_mode: "AR MODE",
	Switch_modal_title: "Skift",
	Switch_modal_content_Video: "Skift til succes med videoopkald!",
	Switch_modal_content_Voice: "Skift til stemmeopkald succes!",
	Video_Call_Label: "Videopkald",
	Functionality_Label: "Funktionalitet",
	Press_Chatting_Button_Label: "Tryk på Chat-knappen for at starte chatterum",
	WSA_Applications_label_full: "Workshop Service Applikationer",
	WSA_Applications_item_card: "Fortsæt",
	WSA_Applications_item_card_diagnostic_support: "Diagnostic Support",
	WSA_Applications_item_card_remote_diagnostics: "Remote Diagnostics",
	WSA_Applications_item_card_service_training: "Service Training",
	WSA_Applications_item_card_technical_support: "Teknisk Support",
	Header_Label_Settings: "Indstillinger",
	Assistant_Label_Bot: "Assistent",
	Assistant_first_message: "Hej, hvordan kan jeg hjælpe dig i dag?",
	Assistant_today_label: "I dag",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Deltag i Chat",
	Your_name_input_label: "Dit navn",
	Code_input_label: "Kode",
	Start_btn_label: "Fortsæt",
	Change_info_label: "Ret info",
	Change_name_label: "Byt namn",
	Change_code_label: "Skift kode",
	assistant_ask_name_message: "Inden vi starter, må jeg venligst bede om dit navn?",
	assistant_ask_code_message: "Indtast venligst din kode for at deltage i sessionen",
	assistant_ask_change_message: "Vælg venligst det element, du vil ændre",
	assistant_ask_change_name_message: "Indtast venligst dit navn",
	assistant_ask_change_code_message: "Indtast venligst kode",
	assistant_ask_change_action_message: "Vælg venligst handlingstype",
	assistant__confirm_message: "Jeg har modtaget dine oplysninger: \n Din kode: [code] \n Dit navn: [name] \n Vil du fortsætte?",
	Assistant_Welcome_Bot: "Velkommen til Tessa Bot!",
	End_meeting_Warning: "Er du sikker på at afslutte mødet?. Mødet kan ikke bruges, når det er slut.",
	Leave_meeting_meesage_Warning: "Du er ved at afslutte opkaldet, klik her for at fortsætte",
	Reject_Switch_modal_content_Voice: "Brugeren tillader ikke, at kameraet tændes",
	Change_action_label: "Skift handlingstype",
	BoschExpert_Label_Sender: "Bosch Agent",
	Retry_note_v2: "Luk venligst modalen og prøv igen eller skift koden",
	End_Channel_note: "Kanalen er slut!",
	Leave_Channel_note: "Teknikeren har forladt kanalen!",
	Join_Channel_note: "Teknikeren er kommet med på kanalen!",
	Translation_is_available: "Oversættelse er tilgængelig!",
	Translation_is_available_detail: "Vi har opdaget, at dit sprog er [tech_language], og at Bosch Experts-sproget er [expert_language]. \n Vil du aktivere live oversættelse?",
	No_Label: "Nej",
	Minimize_Label: "Minimer",
	Maximize_Label: "Maksimer",
	Close_Session_Label: "Luk session",
	Popup_Title_Warning: "Advarsel",
	Show_Message_Label: "Klik her for at se den fulde chathistorik for denne samtale",
	Hide_Message_label: "Klik her for at skjule chathistorikken",
	Close_Channel_Message_Question: "Vil du lukke denne session?",
	Channel_noti_joined_message: "[name] har tilsluttet sig kanalen.",
	Click_to_view_history_message: "Klicka här för att se chatthistoriken",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "På hold",
	Busy_Label: "Optaget",
	OK_Label: "OK",
	Channel_Notification: "Kanalbesked",
	BE_Leave: "Det ser ud til, at Bosch-agenten har været offline i et stykke tid. Du kan fortsætte med at vente eller forlade denne samtale.",
	Chat_LoadingChat_Message: "Indlæser chat.....",
	Popup_Title_Error: "Ups, noget gik galt!"
};