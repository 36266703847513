export const TRANSLATIONS_ET = {
	Logo_Title_Label: "Privaatkanali lahendus",
	Service_Assist_Label: "Teenuse assistent",
	Start_New_LabelBtn: "Ava Uus",
	Start_New_Question: "Kuidas soovite luua uut privaatkanalit?",
	Sms_label: "SMS",
	Email_label: "E-post",
	Ticket_Id_Label: "Pileti ID",
	Code_label: "Kood",
	Channel_List: "Kanalite loend",
	Chat_Label: "Vestlus",
	Call_Label: "Kõne",
	Video_Label: "Video",
	Share_Label: "Jaga",
	Stop_Channel_Label: "Peatage kanal",
	Contact_Details_Label: "Kontaktandmed",
	Request_Details_Label: "Taotlege üksikasju",
	Attendees_Label: "Osalejad",
	Customer_Label: "Klient",
	Bosch_Expert_Label: "Boschi ekspert",
	Type_Your_Message_Label: "Sisestage oma sõnum",
	Stop_Channel_Question: "Kas soovite kanali sulgeda?",
	Yes_Label: "Jah",
	Cancel_Label: "Tühista",
	Start_New_Video_Session: "Alusta uut videoseanssi",
	Without_SMS_Label: "Ilma SMS-ita",
	Send_SMS_Label: "Saada SMS",
	Send_Invite_Label: "Saada kutse",
	Log_Out_Question: "Kas soovite välja logida?",
	Welcome_Label: "Tere tulemast privaatkanalilahendusse",
	Login_Label: "Logi sisse",
	Login_Is_Required_Label: "Kanali käivitamiseks on vaja sisselogimist",
	Account_Permission_Label: "Sellel kontol pole luba kanali käivitamiseks",
	Start_Label: "Alusta",
	Noted_Recommend: "Boschi Firefoxi kasutamise reeglite tõttu soovitame rakenduse Private Channel Solution parima kasutuskogemuse saamiseks kasutada teist brauserit. Soovitatavad brauserid: Microsoft Edge ja Google Chrome",
	Available_Languages_Label: "Saadaolevad keeled",
	Can_Not_Create_Meeting_Title: "Uut privaatkanalit ei saa luua",
	Can_Not_Create_Meeting_Content: "Olete endiselt aktiivses privaatkanalis. Enne uue kanali loomist lõpetage praegune kanal.",
	Leave_meeting_Warning: "Jätke privaatne kanal või saate kanali kõigi jaoks sulgeda. Kanalit ei saa pärast selle lõppemist kasutada.",
	Leave_Lable: "Lahku",
	End_Lable: "Lõpp",
	Meeting_Ended: "Koosolek on lõppenud",
	Start_Channel_Session: "Alustage kanali seanssi",
	Start_Channel_Session_Content: "Tehnik on praegu teisel seansil pileti ID-ga:",
	Join_Lable: "Liitu",
	Join_Meeting_Label: "Liituge kanaliga",
	Meeting_Information_Label: "Kanali teave",
	Meeting_ID_Label: "Kanali ID",
	Translate_Option_Title: "Tõlkevalik",
	Translate_Mode_Label: "Tõlkerežiim:",
	From_Label: "Alates:",
	To_Label: "Saaja:",
	Chat_Room_Label: "Privaatne kanal",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Kuidas soovite privaatkanali kutset jagada?",
	Invite_To_Visual_Question: "Kuidas soovite Visual Connecti kutset jagada?",
	Invite_Sms_Question: "Saada kutse SMS-iga",
	Invite_To_Chat_By_Ticket_ID: "Saatke kutse pileti ID-ga",
	Invite_Email_Question: "Saada kutse meili teel",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Rakendused",
	WSA_Remote_Diagnostics_label: "Kaugdiagnostika",
	WSA_Service_Training_label: "Tehniline koolitus",
	WSA_Enter_website_label: "Sisestage veebisait",
	WSA_Slogan_label: "Loodud Kestma",
	WSA_contact_info_label: "Üldine kontaktteave",
	WSA_PSIRT_label: "Toote turvalisus (PSIRT)",
	WSA_licences_label: "Uuendused, patendid ja litsentsid",
	WSA_purchasing_label: "Ostmine ja logistika",
	WSA_copyright_label: "© Robert Bosch GmbH, kõik õigused kaitstud",
	WSA_copyright_information_label: "Ettevõtte teave",
	WSA_copyright_notice_label: "Õiguslik teade",
	WSA_copyright_protectionnotice_label: "Andmekaitse teatis",
	WSA_copyright_terms_label: "Tingimused",
	WSA_copyright_settings_label: "Privaatsusseaded",
	Infor_CorporateInformation_Description: "Vastutab Robert Bosch GmbH veebilehtede eest",
	Infor_DataProtectionNotice_Description: "Boschi kaugdiagnostika teenuse andmekaitseteatis",
	Infor_LegalNotice_Description: "Robert Bosch GmbH veebilehtede kasutajatele",
	Id_panel: "Id",
	Topic_panel: "Teema",
	Make_panel: "Mark",
	Model_pane: "Mudel",
	Engine_code_panel: "Mootori kood",
	Year_panel: "aasta",
	Vin_panel: "VIN",
	Plate_panel: "Numbrimärk",
	Hardware_panel: "Riistvara",
	ar_mode_warning: "Teie seade ei toeta liitreaalsust",
	Write_something: "Vestluse alustamiseks kirjutage midagi",
	Session_title: "Seanss",
	Live_Translation: "Otsetõlge",
	Enabled_panel: "Lubatud",
	Joined_note: "Olete seansiga liitunud…",
	Today_panel: "Täna",
	Read_panel: "Lugege",
	Sent_panel: "Saadetud",
	Dis_translation: "Keela tõlge",
	En_translation: "Luba tõlge",
	Terminated_Channel_note: "Bosch Expert on kanali sulgenud!",
	Not_start_note: "pole veel alanud!",
	Retry_note: "Uuesti proovimiseks klõpsake nuppu \"Proovi uuesti\" või sulgege see aken väljumiseks.",
	Retry_panel: "Uuesti proovima",
	Thanks_note: "Täname, et kasutasite Boschi tehnilisi teenuseid. Mul on väga hea meel, et meil oli täna võimalus teid aidata. Kui vajate abi, võtke meiega uuesti ühendust. Head päeva sulle.",
	Continue_panel: "Luba reaalajas tõlge",
	En_live_translate: "Jätka",
	Translate_from: "Tõlgi keelest",
	Translate_to: "Tõlgi keelde",
	Random_ID: "Juhuslik ID",
	Anonymous: "Anonüümne",
	Generated_ID: "Juhuslik ID on loodud!",
	Your_ID: "Teie juhuslik ID on:",
	Public_Meeting_ID_Label: "Avaliku koosoleku ID:",
	SelectContactPersonModal_Title: "Avaliku koosoleku ID:",
	SelectContactPersonDropDown_Title: "Kellega sooviksite vestelda?",
	SelectContactPersonDropDown_Error_Message: "Kontaktisik",
	TicketCreator__Title: "Sellel kasutajal pole CIAM ID-d (kasutajakontot CDM-i jaoks), valige jätkamiseks teine kontaktisik ...",
	Wsa_status_title: "(pileti looja)",
	Close_panel: "WSA staatus",
	OCR_Detected_title: "Sulge",
	Voice_Call_Label: "Tuvastatud optiline märgituvastus:",
	Invite_To_Voice_Call_Question: "Kõne",
	AR_mode: "Kuidas sooviksite kõnekutset jagada?",
	Switch_modal_title: "AR REŽIIM",
	Switch_modal_content_Video: "Lülita",
	Switch_modal_content_Voice: "Video- kõnele lülitumine õnnestus!",
	Video_Call_Label: "Kõnele lülitumine õnnestus!",
	Functionality_Label: "Videokõne",
	Press_Chatting_Button_Label: "Funktsionaalsus",
	WSA_Applications_label_full: "Vajutage vestluse nuppu, et alustada vestlustuba",
	WSA_Applications_item_card: "Töökoja teenuse rakendused",
	WSA_Applications_item_card_diagnostic_support: "Sisestage rakendus",
	WSA_Applications_item_card_remote_diagnostics: "Diagnostiline tugi",
	WSA_Applications_item_card_service_training: "Kaugdiagnostika",
	WSA_Applications_item_card_technical_support: "Teeninduse koolitus",
	Header_Label_Settings: "Tehniline tugi",
	Assistant_Label_Bot: "Seaded",
	Assistant_first_message: "Assistent",
	Assistant_today_label: "Tere, kuidas saan teid täna aidata?",
	Visual_Connect_Pro_btn: "Täna",
	Join_chat_btn: "Visual Connect Pro",
	Your_name_input_label: "Liitu vestlusega",
	Code_input_label: "Teie nimi",
	Start_btn_label: "Kood",
	Change_info_label: "Lähme",
	Change_name_label: "Muuda teavet",
	Change_code_label: "Muuda nime",
	assistant_ask_name_message: "Muuda koodi",
	assistant_ask_code_message: "Enne alustamist, kas ma saan teada teie nime?",
	assistant_ask_change_message: "Palun sisestage oma kood, et liituda sessiooniga",
	assistant_ask_change_name_message: "Palun valige üksus, mida soovite muuta",
	assistant_ask_change_code_message: "Palun sisestage oma nimi",
	assistant_ask_change_action_message: "Palun sisestage kood",
	assistant__confirm_message: "Palun valige tegevuse tüüp",
	Assistant_Welcome_Bot: "Olen saanud teie teabe: \n Teie kood: [kood] \n Teie nimi: [nimi] \n Kas soovite jätkata?",
	End_meeting_Warning: "Tere tulemast Tessa Botti!",
	Leave_meeting_meesage_Warning: "Kas olete kindel, et soovite koosoleku lõpetada? Koosolekut ei saa enam kasutada, kui see on lõppenud.",
	Reject_Switch_modal_content_Voice: "Olete kõne lõpetamas, klõpsake siin, et jätkata",
	Change_action_label: "Kasutaja ei luba kaamerat sisse lülitada",
	BoschExpert_Label_Sender: "Muuda tegevuse tüüpi",
	Retry_note_v2: "Bosch agent",
	End_Channel_note: "Palun sulgege aken ja proovige uuesti või muutke koodi",
	Leave_Channel_note: "Kanal on lõppenud!",
	Join_Channel_note: "Tehnik on kanalist lahkunud!",
	Translation_is_available: "Tehnik on kanaliga liitunud!",
	Translation_is_available_detail: "Tõlge on saadaval!",
	No_Label: "Oleme tuvastanud, et teie keel on [tech_language] ja Bosch ekspertide keel on [expert_language]. \n Kas soovite lubada reaalajas tõlke?",
	Minimize_Label: "Ei",
	Maximize_Label: "Minimeeri",
	Close_Session_Label: "Maksimeeri",
	Popup_Title_Warning: "Sulge sessioon",
	Show_Message_Label: "Hoiatus",
	Hide_Message_label: "Klõpsake siin, et vaadata selle vestluse täielikku ajalugu",
	Close_Channel_Message_Question: "Klõpsake siin, et peita vestluse ajalugu",
	Channel_noti_joined_message: "Kas soovite selle sessiooni sulgeda?",
	Click_to_view_history_message: "[nimi] on kanaliga liitunud.",
	Online_Label: "Klõpsake siin, et vaadata vestluse ajalugu",
	Offline_Label: "Võrgus",
	Onhold_Label: "Võrguühenduseta",
	Busy_Label: "Ootel",
	OK_Label: "Hõivatud",
	Channel_Notification: "Okei",
	BE_Leave: "Kanali teade",
	Chat_LoadingChat_Message: "Paistab, et Bosch agent on juba mõnda aega võrguühenduseta olnud. Võite jätkata ootamist või selle vestluse lõpetada.",
	Popup_Title_Error: "Vestluse laadimine....."
};