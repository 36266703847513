import React, { Fragment, useEffect, useState } from 'react';
import {
    ControlBar,
    AudioInputVFControl,
    ContentShareControl,
    AudioOutputControl,
    ControlBarButton,
    VideoInputControl,
    Phone,
    useMeetingManager,
    useAudioVideo,
    PopOver,
    PopOverItem,
    Cog,
    PopOverHeader
} from 'amazon-chime-sdk-component-library-react';
import {
    selectVisualConnectListState,
    visualConnectSetFirstStart
} from "../../redux/visualConnect";
import './MeetingControls.css';
import { useAppDispatch } from '../../redux/store';
import { endMeeting, leaveMeeting, selectMeetingState, changeActionMeeting, selectActionMeetingState, selectListAttendeeState } from '../../redux/meeting';
import { useSelector } from 'react-redux';
import { AudioVideoObserver, VideoTileState } from 'amazon-chime-sdk-js';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Boschicon from '../../common-components/boschIcon/Boschicon';
import { ReactComponent as Flash_Off } from "../../assets/svg/lightbulb-halogen-cracked-disabled-light.svg";
import { v4 } from 'uuid';
import { DATA_MESSAGE_ACTION, DATA_MESSAGE_TYPE } from '../../helpers/AppConstants';
import ModalShowContentOCR from '../../components/modals/ModalShowContentOCR';
import CustomLoader from '../../common-components/RDComponents/CustomLoader';
import useBroadcast from '../../hooks/useBroadcast';

type RealtimeDataCmd = "TEXT" | "WHITEBOARD"

export type RealtimeData = {
    uuid: string
    action: string
    cmd: RealtimeDataCmd
    data: any
    createdDate: number
    senderName: string
}


const MeetingControls = (props: { mtype: string | null, isNewMemberJoined: boolean, resetMemberJoined: any, isWebVersion: boolean }) => {

    const dispatchReduxToolkit = useAppDispatch();
    const meetingManager = useMeetingManager();
    const meetingData = useSelector(selectMeetingState);
    const actionMeeting = useSelector(selectActionMeetingState);
    const vcpState = useSelector(selectVisualConnectListState);
    const [meetingId, setMeetingId] = useState("");
    const [externalMeetingId, setExternalMeetingId] = useState("");
    const [hasRemoteVideo, setRemoteVideo] = useState(false);
    const [remoteVideoIsPause, setRemoteVideoIsPause] = useState(false);
    const [onFlash, setOnFlash] = useState(false);
    const [onAR, setOnAR] = useState(false);
    const [onARDraw, setOnARDraw] = useState(false);
    const [onPointer, setOnPointer] = useState(false);
    const [ArAvailable, setArAvailable] = useState(true);
    const [showArAvailable, setShowArAvailable] = useState(false);
    const [showSwitchFunction, setShowSwitchFunction] = useState(false);
    const [showRejectSwitchFunction, setShowRejectSwitchFunction] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [overNumberAttendees, setOverNumberAttendees] = useState(false);
    const [tempDataCapture, setTempDataCapture] = useState<any>(null);
    const [ocrData, setOcrData] = useState<string>("");
    const [onOcrModal, setOnOcrModal] = useState<boolean>(false);
    const audioVideo = useAudioVideo();
    const { t } = useTranslation();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const toggleModal = (): void => setShowModal(!showModal);
    const [isLoading, setIsLoading] = useState(false);
    const [onVoiceCallMode, setOnVoiceCallMode] = useState<boolean>(false);
    const listAttendee = useSelector(selectListAttendeeState);

    useEffect(() => {
        if (listAttendee && listAttendee.length > 2) {
            setOverNumberAttendees(true)
        } else {
            setOverNumberAttendees(false)
        }
    }, [listAttendee]);

    useEffect(() => {
        if (props.mtype && props.mtype !== "") {
            setOnVoiceCallMode(true)
            setSwitchCallFunctionAction(true);
        } else {
            setOnVoiceCallMode(false)
        }
    }, [props.mtype]);

    useEffect(() => {
        if (props.isNewMemberJoined) {
            setSwitchCallFunctionAction(onVoiceCallMode)
            props.resetMemberJoined()
        }
    }, [props.isNewMemberJoined]);

    useEffect(() => {
        setMeetingId(meetingData.Meeting.meetingId)
        setExternalMeetingId(meetingData.Meeting.externalMeetingId)
        let bodyMessageBroadcast = {
            type: "start_metting",
            metaData: null
        }
        broadcast(JSON.stringify(bodyMessageBroadcast))
    }, [meetingData]);

    useEffect(() => {
        const handleTabClose = () => {
            dispatchReduxToolkit(leaveMeeting());
            dispatchReduxToolkit(visualConnectSetFirstStart(false));
        };
        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    const { broadcast } = useBroadcast<string>({
        channelName: "meeting-status",
        messageHandler: (message: MessageEvent<string>) => { },
    });

    const endMeetingHandle = async () => {
        const bodyMeeting = {
            meetingId,
            externalMeetingId
        }
        let bodyMessageBroadcast = {
            type: "end_metting",
            metaData: bodyMeeting
        }
        await broadcast(JSON.stringify(bodyMessageBroadcast))
        meetingManager.leave();
    }


    const leaveMeetingHandle = async () => {
        let bodyMessageBroadcast = {
            type: "leave_metting",
            metaData: null
        }
        await broadcast(JSON.stringify(bodyMessageBroadcast))
        setTimeout(() => {
            history.push("/pcs");
        }, 2000);
        window.close();
    }

    useEffect(() => {
        if (!audioVideo) {
            return;
        }
        const observer: AudioVideoObserver = {
            videoTileDidUpdate: (tileState: VideoTileState): void => {
                showingBtnPauseCapture(audioVideo);
            }, videoTileWasRemoved: (tileId: number): void => {
                showingBtnPauseCapture(audioVideo);
            }
        };

        audioVideo.addObserver(observer);

        return (): void => audioVideo.removeObserver(observer);
    }, [audioVideo]);

    useEffect(() => {
        let data = { ...actionMeeting };
        data.AR = onAR;
        data.Draw = onARDraw;
        data.MousePointer = onPointer;

        if (!onAR) {
            data.Draw = false;
        }
        dispatchReduxToolkit(changeActionMeeting(data));
    }, [onAR, onARDraw, onPointer]);

    useEffect(() => {
        audioVideo!.realtimeSubscribeToReceiveDataMessage(
            DATA_MESSAGE_TYPE.Command,
            receiveChatData
        )
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    })


    const showingBtnPauseCapture = (audioVideo: any) => {
        const listVideoTile = audioVideo.getAllRemoteVideoTiles();
        if (listVideoTile.length > 0) {
            setRemoteVideo(true);
            listVideoTile[0].state().paused ? setRemoteVideoIsPause(true) : setRemoteVideoIsPause(false)
        }
        else setRemoteVideo(false)
    }

    const pauseVideo = async () => {
        if (!isMobile) {
            await takeTempImageCapture();
            const videoTileList = audioVideo?.getAllRemoteVideoTiles();
            if (videoTileList && videoTileList.length > 0) {
                audioVideo?.pauseVideoTile(videoTileList[0].id())
            }
        } else {
            let uuid = v4();
            const mess: RealtimeData = {
                uuid: uuid,
                action: DATA_MESSAGE_ACTION.PauseVideo,
                cmd: "TEXT",
                data: "pause_video",
                createdDate: new Date().getTime(),
                senderName: 'member' + uuid
            }
            audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
            setRemoteVideoIsPause(true);
            return () => {
                audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
            }
        }
    }

    const unPauseVideo = () => {
        if (!isMobile) {
            const videoTileList = audioVideo?.getAllRemoteVideoTiles()
            if (videoTileList && videoTileList.length > 0) {
                audioVideo?.unpauseVideoTile(videoTileList[0].id())
            }
        } else {
            let uuid = v4();
            const mess: RealtimeData = {
                uuid: uuid,
                action: DATA_MESSAGE_ACTION.UnPauseVideo,
                cmd: "TEXT",
                data: "un_pause_video",
                createdDate: new Date().getTime(),
                senderName: 'member' + uuid
            }
            audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
            setRemoteVideoIsPause(false);
            return () => {
                audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
            }
        }
    }

    const ImageDataToBlob = function (imageData: ImageData) {
        let canvas = document.createElement("canvas");
        canvas.width = imageData.width
        canvas.height = imageData.height
        let ctx = canvas.getContext("2d");
        if (ctx !== null) {
            ctx.putImageData(imageData, 0, 0);
            return new Promise((resolve) => {
                canvas.toBlob(resolve);
            });
        }
    }

    function downloadBlob(blob: any, name = 'capture.png') {
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.target = "blank";
        link.href = blobUrl;
        link.download = name;

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    }

    const captureVideo = async (isPause: boolean) => {
        if (isPause && tempDataCapture) {
            downloadBlob(tempDataCapture);
        } else {
            const videoTileList = audioVideo?.getAllRemoteVideoTiles()
            if (videoTileList && videoTileList.length > 0) {
                // if (meetingData.videoTiles.length > 0) {
                const data = videoTileList[0].capture();
                if (data) {
                    const image: any = await ImageDataToBlob(data);

                    downloadBlob(image);
                }
            }
        }
    }

    const takeTempImageCapture = async () => {

        const videoTileList = audioVideo?.getAllRemoteVideoTiles()
        if (videoTileList && videoTileList.length > 0) {
            const data = videoTileList[0].capture();
            if (data) {
                const image: any = await ImageDataToBlob(data);

                setTempDataCapture(image);
            }
        }
    }

    const handleFlashLight = async (isTurnFlash: boolean) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: isTurnFlash ? DATA_MESSAGE_ACTION.TurnOnFlash : DATA_MESSAGE_ACTION.TurnOffFlash,
            cmd: "TEXT",
            data: isTurnFlash ? "Turn_On_Light" : "Turn_Off_Light",
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        setOnFlash(isTurnFlash);
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }

    }
    const handleAR = async (isTurnAR: boolean) => {
        if (!ArAvailable) {
            setShowArAvailable(true)
            setTimeout(() => setShowArAvailable(false), 7000);
            return;
        }
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: isTurnAR ? DATA_MESSAGE_ACTION.TurnOnAR : DATA_MESSAGE_ACTION.TurnOffAR,
            cmd: "TEXT",
            data: isTurnAR ? "Turn_On_AR" : "Turn_Off_AR",
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        setOnAR(isTurnAR);
        if (!isTurnAR) {
            setDrawAction(false);
        } else {
            setOnPointer(false)
        }
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }
    const handleOCR = async () => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: DATA_MESSAGE_ACTION.OCR,
            cmd: "TEXT",
            data: "OCR",
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        setIsLoading(true);
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }

    const setArAction = async (type: string) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: type,
            cmd: "TEXT",
            data: type,
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }

    const setDrawAction = async (isDraw: boolean) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: isDraw ? DATA_MESSAGE_ACTION.TurnOnDraw : DATA_MESSAGE_ACTION.TurnOffDraw,
            cmd: "TEXT",
            data: isDraw ? DATA_MESSAGE_ACTION.TurnOnDraw : DATA_MESSAGE_ACTION.TurnOffDraw,
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        setOnARDraw(isDraw);
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }
    const setPointerAction = async (isPointer: boolean) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: isPointer ? DATA_MESSAGE_ACTION.TurnOnMouse : DATA_MESSAGE_ACTION.TurnOffMouse,
            cmd: "TEXT",
            data: isPointer ? DATA_MESSAGE_ACTION.TurnOnMouse : DATA_MESSAGE_ACTION.TurnOffMouse,
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        setOnPointer(isPointer);
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }

    const setSwitchCallFunctionAction = async (onVoiceCall: boolean) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: onVoiceCall ? DATA_MESSAGE_ACTION.onVoiceCall : DATA_MESSAGE_ACTION.onVideoCall,
            cmd: "TEXT",
            data: onVoiceCall ? DATA_MESSAGE_ACTION.onVoiceCall : DATA_MESSAGE_ACTION.onVideoCall,
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid,
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }

    const handleSwitchAction = async (onVoiceCall: boolean) => {
        if (!onVoiceCall) {
            setSwitchCallFunctionAction(onVoiceCall);
            return;
        }
        setOnVoiceCallMode(onVoiceCall);
        setSwitchCallFunctionAction(onVoiceCall);
    }


    const receiveChatData = (mess: any) => {
        const data = JSON.parse(mess.text()) as RealtimeData
        //strigger send message type call
        switch (data?.action.trim()) {
            case DATA_MESSAGE_ACTION.TurnOnFlash:
                setOnFlash(true)
                break;
            case DATA_MESSAGE_ACTION.TurnOffFlash:
                setOnFlash(false)
                break;
            case DATA_MESSAGE_ACTION.TurnOnAR:
                setOnAR(true)
                break;
            case DATA_MESSAGE_ACTION.TurnOffAR:
                setOnAR(false)
                break;
            case DATA_MESSAGE_ACTION.ArAvailable:
                setSwitchCallFunctionAction(onVoiceCallMode)
                setArAvailable(true)
                break;
            case DATA_MESSAGE_ACTION.ArNoAvailable:
                setSwitchCallFunctionAction(onVoiceCallMode)
                setArAvailable(false)
                break;
            case DATA_MESSAGE_ACTION.TurnOnDraw:
                setOnARDraw(true)
                break;
            case DATA_MESSAGE_ACTION.TurnOffDraw:
                setOnARDraw(false)
                break;
            case DATA_MESSAGE_ACTION.IsMobile:
                setIsMobile(true)
                break;
            case DATA_MESSAGE_ACTION.OCR:
                let dataOCR = data?.data || "";
                setOcrData(dataOCR);
                setOnOcrModal(true);
                setIsLoading(false);
                break;
            case DATA_MESSAGE_ACTION.acceptVideoCall:
                setOnVoiceCallMode(false);
                setShowSwitchFunction(true);
                setTimeout(() => setShowSwitchFunction(false), 7000);
                break;
            case DATA_MESSAGE_ACTION.rejectVideoCall:
                setOnVoiceCallMode(true);
                setShowRejectSwitchFunction(true);
                setTimeout(() => setShowRejectSwitchFunction(false), 7000);
                break;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <ControlBar
                className="controls-menu"
                layout="undocked-horizontal"
                showLabels
            >
                {
                    onVoiceCallMode ? (
                        <>
                            <div className='local-actions'>
                                <AudioInputVFControl />
                                <AudioOutputControl />
                            </div>
                            {
                                !props.isWebVersion && (<div className='remote-actions'>
                                    <ControlBarButton icon={<Boschicon name={'bosch-ic-replace'} />} label="Switch" onClick={() => handleSwitchAction(!onVoiceCallMode)} />
                                </div>)
                            }
                        </>
                    ) : (
                        <>
                            <div className='local-actions'>
                                <AudioInputVFControl />
                                <VideoInputControl />
                                <AudioOutputControl />
                            </div>

                            <div className='remote-actions'>
                                {
                                    !props.isWebVersion && (<div className='remote-actions'>
                                        <ControlBarButton icon={<Boschicon name={'bosch-ic-replace'} />} label="Switch" onClick={() => handleSwitchAction(!onVoiceCallMode)} />
                                    </div>)
                                }
                                <ContentShareControl />
                                {
                                    hasRemoteVideo && (
                                        <Fragment>
                                            {
                                                remoteVideoIsPause ? (
                                                    <>
                                                        <ControlBarButton icon={<Boschicon name={'bosch-ic-screenshot-frame'} />} label="Capture Screenshot" onClick={() => captureVideo(true)} />
                                                        <ControlBarButton icon={<Boschicon name={'bosch-ic-barcode-scan'} />} label="Optical Character Recognition" onClick={() => handleOCR()} />
                                                        {
                                                            !onAR && (onPointer ? (<ControlBarButton icon={<Boschicon name={'bosch-ic-cursor-connected'} />} label="Off Live Pointer" onClick={() => setPointerAction(false)} />) : (<ControlBarButton icon={<Boschicon name={'bosch-ic-cursor-connected'} />} label="On Live Pointer" onClick={() => setPointerAction(true)} />))
                                                        }

                                                    </>
                                                ) :
                                                    (<ControlBarButton icon={<Boschicon name={'bosch-ic-screenshot-frame'} />} label="Capture Screenshot" onClick={() => captureVideo(false)} />)
                                            }
                                            {
                                                (isMobile && !remoteVideoIsPause && !overNumberAttendees) && (
                                                    <>
                                                        {
                                                            (onAR && ArAvailable) ? (
                                                                <Fragment>
                                                                    <ControlBarButton icon={<Boschicon name={'bosch-ic-augmented-reality-player'} />} label="Augmented Reality" onClick={() => handleAR(false)} />
                                                                    <PopOver
                                                                        a11yLabel='AR Actions'
                                                                        renderButton={isOpen => <ControlBarButton icon={<Cog />} label="AR Actions" onClick={() => { }} />}
                                                                    >
                                                                        <PopOverHeader
                                                                            title='AR Actions'
                                                                        />
                                                                        <PopOverItem
                                                                            as='button'
                                                                            onClick={() => setDrawAction(!onARDraw)}
                                                                            checked={onARDraw}
                                                                            children={<span>Annotate</span>}
                                                                        />
                                                                        <PopOverItem
                                                                            as='button'
                                                                            onClick={() => setArAction(DATA_MESSAGE_ACTION.UndoAR)}
                                                                            children={<span>Undo</span>}
                                                                        />
                                                                        <PopOverItem
                                                                            as='button'
                                                                            onClick={() => setArAction(DATA_MESSAGE_ACTION.RemoveAR)}
                                                                            children={<span>Remove All</span>}
                                                                        />
                                                                    </PopOver>
                                                                </Fragment>
                                                            ) : (<ControlBarButton icon={<Boschicon name={'bosch-ic-augmented-reality'} />} label="Augmented Reality" onClick={() => handleAR(true)} />)
                                                        }
                                                        <ControlBarButton icon={<Boschicon name={'bosch-ic-barcode-scan'} />} label="Optical Character Recognition" onClick={() => handleOCR()} />
                                                        {
                                                            !onAR && (onPointer ? (<ControlBarButton icon={<Boschicon name={'bosch-ic-cursor-connected'} />} label="Off Live Pointer" onClick={() => setPointerAction(false)} />) : (<ControlBarButton icon={<Boschicon name={'bosch-ic-cursor-connected'} />} label="On Live Pointer" onClick={() => setPointerAction(true)} />))
                                                        }
                                                        {
                                                            (onFlash) ? (<ControlBarButton icon={<Boschicon name={'bosch-ic-lightbulb-halogen-rays-bright'} />} label="Flashlight" onClick={() => handleFlashLight(false)} />) : (<ControlBarButton icon={<Icon><Flash_Off /></Icon>} label="Enable flashlight" onClick={() => handleFlashLight(true)} />)
                                                        }
                                                    </>
                                                )
                                            }
                                            {
                                                remoteVideoIsPause ? (<ControlBarButton icon={<Boschicon name={'bosch-ic-play'} />} label="Start Video" onClick={unPauseVideo} />) :
                                                    (<ControlBarButton icon={<Boschicon name={'bosch-ic-pause'} />} label="Pause Video" onClick={pauseVideo} />)
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        </>
                    )
                }
                <ControlBarButton icon={<Phone />} label="Leave" onClick={toggleModal} />
                <Modal
                    onClose={toggleModal}
                    open={showModal}
                    dimmer={{ className: "test-dimmer black-dimmer" }}
                    closeOnDimmerClick={false}
                    size={"mini"}
                    closeIcon={true}
                >
                    <Modal.Content>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "1rem 0",
                                fontSize: "1.25rem",
                            }}
                        >
                            {vcpState.hasFirstStart ? t("End_meeting_Warning") : t("Leave_meeting_Warning")}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            padding: "1rem 0",
                        }}>
                            {
                                vcpState.hasFirstStart ? (
                                    <>
                                        <Button className="bwc-btn--secondary" onClick={endMeetingHandle}>{t("End_Lable")}</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button className="bwc-btn--primary" onClick={leaveMeetingHandle}>{t("Leave_Lable")}</Button>
                                        <Button className="bwc-btn--secondary" onClick={endMeetingHandle}>{t("End_Lable")}</Button>
                                    </>
                                )
                            }

                        </div>
                    </Modal.Content>
                </Modal>
            </ControlBar >
            {(hasRemoteVideo && showArAvailable) &&
                (
                    <div className='popup--warning--ar-function'>
                        <Message warning>
                            <Message.Header>{t("AR_mode")}</Message.Header>
                            <p>{t("ar_mode_warning")}</p>
                        </Message>
                    </div>
                )
            }
            {isLoading &&
                <Fragment>
                    <CustomLoader enable={isLoading}></CustomLoader>
                </Fragment>}
            {onOcrModal && (
                <ModalShowContentOCR isOpen={onOcrModal} onClose={() => setOnOcrModal(false)} ocrData={ocrData}></ModalShowContentOCR>
            )}
            {showSwitchFunction &&
                (
                    <div className='popup--warning--ar-function'>
                        <Message success>
                            <Message.Header>{t("Switch_modal_title")}</Message.Header>
                            {
                                !onVoiceCallMode ? (
                                    <p>{t("Switch_modal_content_Video")}</p>
                                ) : (<p>{t("Switch_modal_content_Voice")}</p>)
                            }

                        </Message>
                    </div>
                )
            }
            {showRejectSwitchFunction &&
                (
                    <div className='popup--warning--ar-function'>
                        <Message warning>
                            <Message.Header>{t("Switch_modal_title")}</Message.Header>
                            <p>{t("Reject_Switch_modal_content_Voice")}</p>
                        </Message>
                    </div>
                )
            }
        </Fragment>

    );
};

export default MeetingControls;
