export const TRANSLATIONS_NO = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Opprett ny",
	Start_New_Question: "Hvordan vil du starte en ny Private Channel?",
	Sms_label: "SMS",
	Email_label: "E-post",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kode",
	Channel_List: "Kanalliste",
	Chat_Label: "Chat",
	Call_Label: "Anrop",
	Video_Label: "Video",
	Share_Label: "Dele",
	Stop_Channel_Label: "Stopp kanal",
	Contact_Details_Label: "Kontaktinformasjon",
	Request_Details_Label: "Ticket Detaljer",
	Attendees_Label: "Deltakere",
	Customer_Label: "Kunde",
	Bosch_Expert_Label: "Bosch Ekspert",
	Type_Your_Message_Label: "Skriv inn meldingen din",
	Stop_Channel_Question: "Vil du stenge kanalen?",
	Yes_Label: "Ja",
	Cancel_Label: "Avbryt",
	Start_New_Video_Session: "Start ny videosamtale",
	Without_SMS_Label: "Uten SMS",
	Send_SMS_Label: "Send SMS",
	Send_Invite_Label: "Send invitasjon",
	Log_Out_Question: "Vil du logge ut?",
	Welcome_Label: "Velkommen til Private Channel Solution",
	Login_Label: "Logg inn",
	Login_Is_Required_Label: "Innlogging kreves for å starte kanalen",
	Account_Permission_Label: "Denne kontoen har ikke tillatelse til å starte en kanal",
	Start_Label: "Start",
	Noted_Recommend: "På grunn av Boschs retningslinjer for bruk av Firefox, anbefaler vi at du bruker en annen nettleser for å få den beste brukeropplevelsen av Private Channel Solution-applikasjonen. Anbefalte nettlesere: Microsoft Edge og Google Chrome",
	Available_Languages_Label: "Tilgjengelige språk",
	Can_Not_Create_Meeting_Title: "Kan ikke opprette ny privat kanal",
	Can_Not_Create_Meeting_Content: "Du er fortsatt i en aktiv privat kanal. Avslutt gjeldende kanal før du starter en ny.",
	Leave_meeting_Warning: "Gå ut av den private kanalen, eller du kan avslutte kanalen for alle. Kanalen kan ikke brukes når den avsluttes.",
	Leave_Lable: "Forlat",
	End_Lable: "Avslutt",
	Meeting_Ended: "Møtet er avsluttet",
	Start_Channel_Session: "Start kanal",
	Start_Channel_Session_Content: "Teknikeren er for øyeblikket i gang med Ticket ID:",
	Join_Lable: "Delta",
	Join_Meeting_Label: "Delta i kanal",
	Meeting_Information_Label: "Kanalinformasjon",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Oversettelsesaltertnativ",
	Translate_Mode_Label: "Oversettelse:",
	From_Label: "Fra:",
	To_Label: "Til:",
	Chat_Room_Label: "Privat Kanal",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Hvordan vil du dele invitasjonen til kanalen?",
	Invite_To_Visual_Question: "Hvordan vil du dele Visual Connect-invitasjonen?",
	Invite_Sms_Question: "Send invitasjon på SMS",
	Invite_To_Chat_By_Ticket_ID: "Send invitasjon med Ticket ID",
	Invite_Email_Question: "Send invitasjon på e-post",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applikasjoner",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Besøk nettsiden",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "Generell kontaktinformasjon",
	WSA_PSIRT_label: "Produktsikkerhet (PSIRT)",
	WSA_licences_label: "Innovasjoner, patenter og lisenser",
	WSA_purchasing_label: "Innkjøp og logistikk",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	WSA_copyright_information_label: "Bedriftsinformasjon",
	WSA_copyright_notice_label: "Juridisk varsel",
	WSA_copyright_protectionnotice_label: "Merknad om databeskyttelse",
	WSA_copyright_terms_label: "Betingelser og vilkår",
	WSA_copyright_settings_label: "Personverninnstillinger",
	Infor_CorporateInformation_Description: "Ansvarlig for internettsidene til Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Merknad om databeskyttelse for Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "For brukere av internettsidene til Robert Bosch GmbH",
	Id_panel: "Id",
	Topic_panel: "Emne",
	Make_panel: "Merke",
	Model_pane: "Modell",
	Engine_code_panel: "Motorkode",
	Year_panel: "Årsmodell",
	Vin_panel: "VIN",
	Plate_panel: "Skiltnr.",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Enheten din støtter ikke Augmented Reality",
	Write_something: "Skriv noe for å begynne å chatte",
	Session_title: "Kanal",
	Live_Translation: "Oversett",
	Enabled_panel: "Aktivert",
	Joined_note: "Du deltar nå i kanalen…",
	Today_panel: "Idag",
	Read_panel: "Lest",
	Sent_panel: "Sendt",
	Dis_translation: "Deaktiver oversettelse",
	En_translation: "Oversett",
	Terminated_Channel_note: "Din Bosch Ekspert har stengt denne kanalen!",
	Not_start_note: "har ikke startet enda!",
	Retry_note: "Klikk \"Prøv på nytt\" for å prøve igjen eller lukk dette vinduet for å avslutte.",
	Retry_panel: "Prøv igjen",
	Thanks_note: "Takk for at du bruker Bosch teknisk support. Jeg er veldig glad for at vi fikk muligheten til å hjelpe deg i dag. Kontakt oss igjen hvis du trenger hjelp. Ha en fin dag.",
	Continue_panel: "Aktiver oversettelse",
	En_live_translate: "Fortsett",
	Translate_from: "Oversett fra",
	Translate_to: "Oversett til",
	Random_ID: "Random ID",
	Anonymous: "Anonym",
	Generated_ID: "Random ID er generert!",
	Your_ID: "Din \"Random ID\" er:",
	Public_Meeting_ID_Label: "Møte ID:",
	SelectContactPersonModal_Title: "Hvem vil du chatte med?",
	SelectContactPersonDropDown_Title: "Kontaktperson",
	SelectContactPersonDropDown_Error_Message: "Denne brukeren har ikke en ciam-ID (brukerkonto for CDM), Velg en annen kontaktperson for å fortsette ...",
	TicketCreator__Title: "(forespørgselsskaber)",
	Wsa_status_title: "WSA status",
	Close_panel: "Steng",
	OCR_Detected_title: "Optisk tegngjenkjenning oppdaget:",
	Voice_Call_Label: "Taleanrop",
	Invite_To_Voice_Call_Question: "Hvordan vil du dele invitasjonen til taleanrop?",
	AR_mode: "AR MODE",
	Switch_modal_title: "Bytt",
	Switch_modal_content_Video: "Bytt til suksess med videosamtaler!",
	Switch_modal_content_Voice: "Bytt til suksess for taleanrop!",
	Video_Call_Label: "Videosamtale",
	Functionality_Label: "Funksjonalitet",
	Press_Chatting_Button_Label: "Trykk på Chat-knappen for å starte chatterom",
	WSA_Applications_label_full: "Workshop Service Applikasjoner",
	WSA_Applications_item_card: "Fortsett",
	WSA_Applications_item_card_diagnostic_support: "Diagnostic Support",
	WSA_Applications_item_card_remote_diagnostics: "Remote Diagnostics",
	WSA_Applications_item_card_service_training: "Service Training",
	WSA_Applications_item_card_technical_support: "Teknisk Support",
	Header_Label_Settings: "Innstillinger",
	Assistant_Label_Bot: "Assistent",
	Assistant_first_message: "Hei, hvordan kan jeg hjelpe deg i dag?",
	Assistant_today_label: "I dag",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Bli med i Chat",
	Your_name_input_label: "Navnet ditt",
	Code_input_label: "Kode",
	Start_btn_label: "Fortsett",
	Change_info_label: "Endre info",
	Change_name_label: "Endre navn",
	Change_code_label: "Endre kode",
	assistant_ask_name_message: "Før vi begynner, kan jeg få vite navnet ditt?",
	assistant_ask_code_message: "Vennligst skriv inn koden din for å bli med på økten",
	assistant_ask_change_message: "Velg elementet du vil endre",
	assistant_ask_change_name_message: "Vennligst skriv inn navnet ditt",
	assistant_ask_change_code_message: "Vennligst skriv inn kode",
	assistant_ask_change_action_message: "Velg handlingstype",
	assistant__confirm_message: "Jeg har mottatt informasjonen din: \n Din kode: [code] \n Ditt navn: [name] \n Vil du fortsette?",
	Assistant_Welcome_Bot: "Velkommen til Tessa Bot!",
	End_meeting_Warning: "Er du sikker på å avslutte møtet?. Møtet kan ikke brukes når det er over.",
	Leave_meeting_meesage_Warning: "Du er i ferd med å avslutte samtalen, klikk her for å fortsette",
	Reject_Switch_modal_content_Voice: "Brukeren tillater ikke at kameraet slås på",
	Change_action_label: "Endre handlingstype",
	BoschExpert_Label_Sender: "Bosch Agent",
	Retry_note_v2: "Lukk modalen og prøv igjen eller endre koden",
	End_Channel_note: "Kanalen er avsluttet!",
	Leave_Channel_note: "Teknikeren har forlatt kanalen!",
	Join_Channel_note: "Teknikeren har sluttet seg til kanalen!",
	Translation_is_available: "Oversettelse er tilgjengelig!",
	Translation_is_available_detail: "Vi har oppdaget at språket ditt er [tech_language] og at Bosch Experts-språket er [expert_language]. \n Vil du aktivere live oversettelse?",
	No_Label: "Nei",
	Minimize_Label: "Minimer",
	Maximize_Label: "Maksimer",
	Close_Session_Label: "Lukk økten",
	Popup_Title_Warning: "Advarsel",
	Show_Message_Label: "Klikk her for å se hele chat-loggen for denne samtalen",
	Hide_Message_label: "Klikk her for å skjule chatloggen",
	Close_Channel_Message_Question: "Vil du avslutte denne økten?",
	Channel_noti_joined_message: "[name] har blitt med i kanalen.",
	Click_to_view_history_message: "Klicka här för att se chatthistoriken",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "På vent",
	Busy_Label: "Travel",
	OK_Label: "OK",
	Channel_Notification: "Kanalvarsling",
	BE_Leave: "Det ser ut til at Bosch-agenten har vært offline en stund. Du kan fortsette å vente eller forlate denne samtalen.",
	Chat_LoadingChat_Message: "Laster chat.....",
	Popup_Title_Error: "Beklager, noe gikk galt!"
};