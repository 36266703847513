export const TRANSLATIONS_LV = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Servisa palīgs",
	Start_New_LabelBtn: "Jauns",
	Start_New_Question: "Kā jūs vēlētos izveidot jaunu privāto kanālu?",
	Sms_label: "Īsziņa",
	Email_label: "E-pasts",
	Ticket_Id_Label: "Pieprāsijuma ID",
	Code_label: "Kods",
	Channel_List: "Kanālu saraksts",
	Chat_Label: "Tērzēšana",
	Call_Label: "Zvanīt",
	Video_Label: "Video",
	Share_Label: "Dalīties",
	Stop_Channel_Label: "Apturēt kanālu",
	Contact_Details_Label: "Kontaktinformācija",
	Request_Details_Label: "Pieprasīt sīkāku informāciju",
	Attendees_Label: "Dalībnieki",
	Customer_Label: "Klients",
	Bosch_Expert_Label: "Bosch eksperts",
	Type_Your_Message_Label: "Ierakstiet savu ziņojumu",
	Stop_Channel_Question: "Vai vēlaties aizvērt kanālu?",
	Yes_Label: "Jā",
	Cancel_Label: "Atcelt",
	Start_New_Video_Session: "Sāciet jaunu video sesiju",
	Without_SMS_Label: "Bez īsziņas",
	Send_SMS_Label: "Sūtīt īsziņu",
	Send_Invite_Label: "Nosūtīt uzaicinājumu",
	Log_Out_Question: "Vai vēlaties izrakstīties?",
	Welcome_Label: "Laipni lūdzam Private Channel Solution",
	Login_Label: "Pieslēgties",
	Login_Is_Required_Label: "Lai izveidot kanālu, ir jāpierakstās",
	Account_Permission_Label: "Šim kontam nav atļaujas veidot kanālu",
	Start_Label: "Sākt",
	Noted_Recommend: "Ņemot vērā Bosch Firefox lietošanas politiku, mēs iesakām izmantot citu pārlūkprogrammu, lai iegūtu vislabāko lietojumprogrammas Private Channel Solution lietošanas pieredzi. Ieteicamās pārlūkprogrammas: Microsoft Edge un Google Chrome",
	Available_Languages_Label: "Pieejamās valodas",
	Can_Not_Create_Meeting_Title: "Nevar izveidot jaunu privāto kanālu",
	Can_Not_Create_Meeting_Content: "Jūs joprojām esat aktīvajā privātajā kanālā. Lūdzu, pārtrauciet pašreizējo kanālu, pirms sākat jaunu.",
	Leave_meeting_Warning: "Atstājiet privāto kanālu vai arī varat pārtraukt kanālu visiem dalībniekiem. Kad tas beidzas, kanālu nevar izmantot.",
	Leave_Lable: "Iziet",
	End_Lable: "Beigas",
	Meeting_Ended: "Sesijas beiga",
	Start_Channel_Session: "Sāciet kanāla sesiju",
	Start_Channel_Session_Content: "Tehniķis pašlaik ir aizņemts citā sesijā ar pieteikuma ID:",
	Join_Lable: "Pievienties",
	Join_Meeting_Label: "Pievienoties kanālam",
	Meeting_Information_Label: "Informācija par kanālu",
	Meeting_ID_Label: "Kanāla ID",
	Translate_Option_Title: "Tulkošanas opcija",
	Translate_Mode_Label: "Tulkošanas režīms:",
	From_Label: "No:",
	To_Label: "Kam:",
	Chat_Room_Label: "Privāts kanāls",
	Visual_Connect_Label: "Vizuālais savienojums",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Kā jūs vēlētos kopīgot privātā kanāla ielūgumu?",
	Invite_To_Visual_Question: "Kā jūs vēlētos kopīgot Visual Connect ielūgumu?",
	Invite_Sms_Question: "Nosūtiet uzaicinājumu SMS",
	Invite_To_Chat_By_Ticket_ID: "Nosūtiet uzaicinājumu, izmantojot pieteikuma ID",
	Invite_Email_Question: "Nosūtiet uzaicinājumu izmantojot e-pastu",
	Link_label: "Saite",
	WSA_label: "Darbnīcas servisa palīgs",
	WSA_Applications_label: "Lietojumprogrammas",
	WSA_Remote_Diagnostics_label: "Attālināta diagnostika",
	WSA_Service_Training_label: "Servisa apmācība",
	WSA_Enter_website_label: "Ievadiet vietni",
	WSA_Slogan_label: "Tehnika dzIvei",
	WSA_contact_info_label: "Vispārīga kontaktinformācija",
	WSA_PSIRT_label: "Produktu drošība (PSIRT)",
	WSA_licences_label: "Inovācijas, patenti un licences",
	WSA_purchasing_label: "Iepirkšana un loģistika",
	WSA_copyright_label: "© Robert Bosch GmbH, visas tiesības ir aizsargātas",
	WSA_copyright_information_label: "Korporatīvā informācija",
	WSA_copyright_notice_label: "Juridisks paziņojums",
	WSA_copyright_protectionnotice_label: "Paziņojums par datu aizsardzību",
	WSA_copyright_terms_label: "Noteikumi",
	WSA_copyright_settings_label: "Privātuma iestatījumi",
	Infor_CorporateInformation_Description: "Atbildīgs par Robert Bosch GmbH interneta lapām",
	Infor_DataProtectionNotice_Description: "Bosch attālinātas diagnostikas pakalpojuma datu aizsardzības paziņojums",
	Infor_LegalNotice_Description: "Robert Bosch GmbH interneta lapu lietotājiem",
	Id_panel: "Id",
	Topic_panel: "Temats",
	Make_panel: "Veidot",
	Model_pane: "Modelis",
	Engine_code_panel: "Dzinēja kods",
	Year_panel: "gads",
	Vin_panel: "Vin",
	Plate_panel: "Numura zīme",
	Hardware_panel: "Iekārta",
	ar_mode_warning: "Jūsu ierīce neatbalsta paplašināto realitāti",
	Write_something: "Uzrakstiet kaut ko, lai sāktu tērzēšanu",
	Session_title: "Sesija",
	Live_Translation: "Tiešā tulkošana",
	Enabled_panel: "Iespējots",
	Joined_note: "Jūs esat pievienojies sesijai…",
	Today_panel: "Šodien",
	Read_panel: "Lasīt",
	Sent_panel: "Nosūtīts",
	Dis_translation: "Atspējot tulkošanu",
	En_translation: "Iespējot tulkošanu",
	Terminated_Channel_note: "Bosch Expert ir pārtraucis kanālu!",
	Not_start_note: "vēl nav sācies!",
	Retry_note: "Lūdzu, noklikšķiniet uz “Mēģināt vēlreiz”, lai mēģinātu vēlreiz, vai aizveriet šo logu, lai izietu.",
	Retry_panel: "Mēģiniet vēlreiz",
	Thanks_note: "Paldies, ka izmantojāt Bosch pakalpojumus. Esmu ļoti gandarīts, ka mums šodien bija iespēja jums palīdzēt. Lūdzu, sazinieties ar mums vēlreiz, ja jums nepieciešama palīdzība. Lai jums brīnišķīga diena.",
	Continue_panel: "Iespējot tiešo tulkošanu",
	En_live_translate: "Turpināt",
	Translate_from: "Tulkot no",
	Translate_to: "Tulkot uz",
	Random_ID: "Nejauši izvēlēts ID",
	Anonymous: "Anonīms",
	Generated_ID: "Nejauši izvēlēts ID ir ģenerēts!",
	Your_ID: "Jūsu nejauši izvēlēts ID ir:",
	Public_Meeting_ID_Label: "Publiskās sesijas ID:",
	SelectContactPersonModal_Title: "Publiskās tikšanās ID",
	SelectContactPersonDropDown_Title: "Ar ko vēlaties tērzēt?",
	SelectContactPersonDropDown_Error_Message: "Kontaktpersona",
	TicketCreator__Title: "Šim lietotājam nav ciam id (lietotāja konts CDM), izvēlieties citu kontaktpersonu, lai turpinātu ...",
	Wsa_status_title: "(biļetes veidotājs)",
	Close_panel: "WSA statuss",
	OCR_Detected_title: "Aizvērt",
	Voice_Call_Label: "Tika konstatēta optiskā rakstzīmju atpazīšana:",
	Invite_To_Voice_Call_Question: "Balss zvans",
	AR_mode: "Kā vēlaties kopīgot balss zvana uzaicinājumu?",
	Switch_modal_title: "AR režīms",
	Switch_modal_content_Video: "Pārslēgt",
	Switch_modal_content_Voice: "Pārslēgšanās uz video zvanu veiksmīga!",
	Video_Call_Label: "Pārslēgšanās uz balss zvanu veiksmīga!",
	Functionality_Label: "Video zvans",
	Press_Chatting_Button_Label: "Funkcionalitāte",
	WSA_Applications_label_full: "Nospiediet Tērzēšanas pogu, lai sāktu tērzēšanas istabu",
	WSA_Applications_item_card: "Darbnīcas pakalpojumu lietojumprogrammas",
	WSA_Applications_item_card_diagnostic_support: "Ieejiet lietojumprogrammā",
	WSA_Applications_item_card_remote_diagnostics: "Diagnostikas atbalsts",
	WSA_Applications_item_card_service_training: "Attālinātā diagnostika",
	WSA_Applications_item_card_technical_support: "Apkalpošanas apmācība",
	Header_Label_Settings: "Tehniskais atbalsts",
	Assistant_Label_Bot: "Iestatījumi",
	Assistant_first_message: "Asistents",
	Assistant_today_label: "Sveiki, kā es varu jums palīdzēt šodien?",
	Visual_Connect_Pro_btn: "Šodien",
	Join_chat_btn: "Visual Connect Pro",
	Your_name_input_label: "Pievienoties tērzēšanai",
	Code_input_label: "Jūsu vārds",
	Start_btn_label: "Kods",
	Change_info_label: "Aiziet",
	Change_name_label: "Mainīt informāciju",
	Change_code_label: "Mainīt vārdu",
	assistant_ask_name_message: "Mainīt kodu",
	assistant_ask_code_message: "Pirms mēs sākam, vai varat pateikt savu vārdu?",
	assistant_ask_change_message: "Lūdzu, ievadiet savu kodu, lai pievienotos sesijai",
	assistant_ask_change_name_message: "Lūdzu, izvēlieties vienumu, kuru vēlaties mainīt",
	assistant_ask_change_code_message: "Lūdzu, ievadiet savu vārdu",
	assistant_ask_change_action_message: "Lūdzu, ievadiet kodu",
	assistant__confirm_message: "Lūdzu, izvēlieties darbības veidu",
	Assistant_Welcome_Bot: "Esmu saņēmis jūsu informāciju: \n Jūsu kods: [kods] \n Jūsu vārds: [vārds] \n Vai vēlaties turpināt?",
	End_meeting_Warning: "Laipni lūdzam Tessa Bot!",
	Leave_meeting_meesage_Warning: "Vai tiešām vēlaties beigt sapulci? Sapulci vairs nevarēs izmantot, kad tā būs beigusies.",
	Reject_Switch_modal_content_Voice: "Jūs gatavojaties beigt zvanu, noklikšķiniet šeit, lai turpinātu",
	Change_action_label: "Lietotājs neļauj ieslēgt kameru",
	BoschExpert_Label_Sender: "Mainīt darbības veidu",
	Retry_note_v2: "Bosch aģents",
	End_Channel_note: "Lūdzu, aizveriet logu un mēģiniet vēlreiz vai nomainiet kodu",
	Leave_Channel_note: "Kanāls ir beidzies!",
	Join_Channel_note: "Tehniķis ir atstājis kanālu!",
	Translation_is_available: "Tehniķis ir pievienojies kanālam!",
	Translation_is_available_detail: "Tulkotājs ir pieejams!",
	No_Label: "Mēs esam konstatējuši, ka jūsu valoda ir [tech_language] un Bosch eksperta valoda ir [expert_language]. \n Vai vēlaties aktivizēt tiešraides tulkošanu?",
	Minimize_Label: "Nē",
	Maximize_Label: "Samazināt",
	Close_Session_Label: "Maksimizēt",
	Popup_Title_Warning: "Aizvērt sesiju",
	Show_Message_Label: "Brīdinājums",
	Hide_Message_label: "Noklikšķiniet šeit, lai skatītu pilnu šīs sarunas tērzēšanas vēsturi",
	Close_Channel_Message_Question: "Noklikšķiniet šeit, lai paslēptu tērzēšanas vēsturi",
	Channel_noti_joined_message: "Vai vēlaties aizvērt šo sesiju?",
	Click_to_view_history_message: "[vārds] ir pievienojies kanālam.",
	Online_Label: "Noklikšķiniet šeit, lai skatītu tērzēšanas vēsturi",
	Offline_Label: "Tiešsaistē",
	Onhold_Label: "Bezsaistē",
	Busy_Label: "Uzgaidiet",
	OK_Label: "Aizņemts",
	Channel_Notification: "Labi",
	BE_Leave: "Kanāla paziņojums",
	Chat_LoadingChat_Message: "Izskatās, ka Bosch aģents kādu laiku ir bezsaistē. Jūs varat turpināt gaidīt vai iziet no šīs sarunas.",
	Popup_Title_Error: "Tiek ielādēta tērzēšana....."
};