export const TRANSLATIONS_SV = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Ny Kanal",
	Start_New_Question: "Hur vill du starta en ny kanal?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kod",
	Channel_List: "Kanallista",
	Chat_Label: "Chat",
	Call_Label: "Samtal",
	Video_Label: "Video",
	Share_Label: "Dela",
	Stop_Channel_Label: "Stoppa Kanal",
	Contact_Details_Label: "Kontaktdetaljer",
	Request_Details_Label: "Förfrågningsdetaljer",
	Attendees_Label: "Deltagare",
	Customer_Label: "Kunder",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Skriv ditt meddelande",
	Stop_Channel_Question: "Vill du stänga kanalen?",
	Yes_Label: "Ja",
	Cancel_Label: "Avbryt",
	Start_New_Video_Session: "Starta nytt videosamtal",
	Without_SMS_Label: "Utan SMS",
	Send_SMS_Label: "Skicka SMS",
	Send_Invite_Label: "Skicka inbjudan",
	Log_Out_Question: "Vill du logga ut?",
	Welcome_Label: "Välkommen tilll Private Channel Solution",
	Login_Label: "Logga in",
	Login_Is_Required_Label: "För att starta en kanal krävs att du loggar in",
	Account_Permission_Label: "Detta konto har inte rättigheter att starta ny kanal",
	Start_Label: "Start",
	Noted_Recommend: "På grund av Boschs riktlinjer för användning av Firefox rekommenderar vi att du använder en annan webbläsare för att få den bästa användarupplevelsen av applikationen Private Channel Solution. Rekommenderade webbläsare: Microsoft Edge och Google Chrome",
	Available_Languages_Label: "Tillgängliga språk",
	Can_Not_Create_Meeting_Title: "Det gick inte att skapa en ny Private Channel",
	Can_Not_Create_Meeting_Content: "Du är fortfarande i en aktiv Private Channel. Avsluta den aktuella kanalen innan du startar en ny.",
	Leave_meeting_Warning: "Stäng Private Channel kanalen eller så kan du avsluta kanalen för alla. Kanalen kan inte användas när den är avslutad.",
	Leave_Lable: "Lämna",
	End_Lable: "Avsluta",
	Meeting_Ended: "Mötet är avslutat",
	Start_Channel_Session: "Starta ny kanal session",
	Start_Channel_Session_Content: "Teknikern arbetar för närvarande med Ticket ID:",
	Join_Lable: "Delta",
	Join_Meeting_Label: "Delta i kanalen",
	Meeting_Information_Label: "Kanal information",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Översättningsalternativ",
	Translate_Mode_Label: "Översättning",
	From_Label: "Från:",
	To_Label: "Till:",
	Chat_Room_Label: "Privat Kanal (Private Channel)",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Hur vill du dela Private Channel inbjudan",
	Invite_To_Visual_Question: "Hur vill du dela Visual Connect inbjudan",
	Invite_Sms_Question: "Skicka inbjudan per SMS",
	Invite_To_Chat_By_Ticket_ID: "Skicka inbjudan med Ticket ID",
	Invite_Email_Question: "Skicka inbjudan per Email",
	Link_label: "Länk",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applikationer",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Gå in på webbplatsen",
	WSA_Slogan_label: "Uppfunnet för livet",
	WSA_contact_info_label: "Allmän kontaktinformation",
	WSA_PSIRT_label: "Produktsäkerhet (PSIRT)",
	WSA_licences_label: "Innovationer, patent och licenser",
	WSA_purchasing_label: "Inköp och logistik",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	WSA_copyright_information_label: "Företagsinformation",
	WSA_copyright_notice_label: "Rättsligt meddelande",
	WSA_copyright_protectionnotice_label: "Dataskyddsmeddelande",
	WSA_copyright_terms_label: "Allmänna Villkor",
	WSA_copyright_settings_label: "Sekretessinställningar",
	Infor_CorporateInformation_Description: "Ansvarig för Robert Bosch GmbH:s internetsidor",
	Infor_DataProtectionNotice_Description: "Dataskyddsmeddelande för Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "För användare av Robert Bosch GmbH:s internetsidor",
	Id_panel: "Id",
	Topic_panel: "Ämne",
	Make_panel: "Märke",
	Model_pane: "Modell",
	Engine_code_panel: "Motorkod",
	Year_panel: "Årsmodell",
	Vin_panel: "VIN/Chassi nummer",
	Plate_panel: "Registrerings nummer",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Din tekniker är inte tillgänglig för AR",
	Write_something: "Skriv för att börja chatta",
	Session_title: "Session",
	Live_Translation: "Live översättning",
	Enabled_panel: "Aktiverad",
	Joined_note: "Du har gått med i sessionen...",
	Today_panel: "Idag",
	Read_panel: "Läst",
	Sent_panel: "Skickad",
	Dis_translation: "Deaktivera översättning",
	En_translation: "Översätt",
	Terminated_Channel_note: "Bosch Expert har stängt denna kanal",
	Not_start_note: "har inte startat än",
	Retry_note: "Klicka på \"Försök igen\" för att försöka igen eller stäng det här fönstret för att avsluta.",
	Retry_panel: "Försök igen",
	Thanks_note: "Tack för att du använder Bosch Technical Services. Jag är mycket glad att vi fick möjligheten att hjälpa dig idag. Kontakta oss igen om du behöver hjälp. Ha en underbar dag.",
	Continue_panel: "Aktivera översättning",
	En_live_translate: "Fortsätt",
	Translate_from: "Översätt från",
	Translate_to: "Översätt till",
	Random_ID: "Random ID",
	Anonymous: "Anonym",
	Generated_ID: "Random ID har genererats!",
	Your_ID: "Ditt \"Random ID\" är:",
	Public_Meeting_ID_Label: "Mötes-ID:",
	SelectContactPersonModal_Title: "Vem skulle du vilja chatta med?",
	SelectContactPersonDropDown_Title: "Kontaktperson",
	SelectContactPersonDropDown_Error_Message: "Denna användare har inget ciam-id (användarkonto för CDM), Välj en annan kontaktperson för att fortsätta ...",
	TicketCreator__Title: "(förfrågan skapare)",
	Wsa_status_title: "WSA status",
	Close_panel: "Stäng",
	OCR_Detected_title: "Optisk teckenigenkänning upptäckt:",
	Voice_Call_Label: "Röstsamtal",
	Invite_To_Voice_Call_Question: "Hur skulle du vilja dela inbjudan till röstsamtal?",
	AR_mode: "AR MODE",
	Switch_modal_title: "Byt",
	Switch_modal_content_Video: "Byt till framgång för videosamtal!",
	Switch_modal_content_Voice: "Byt till röstsamtal framgång!",
	Video_Call_Label: "Videosamtal",
	Functionality_Label: "Funktionalitet",
	Press_Chatting_Button_Label: "Tryck på chattknappen för att starta chattrummet",
	WSA_Applications_label_full: "Workshop Service Applikationer",
	WSA_Applications_item_card: "Fortsätt",
	WSA_Applications_item_card_diagnostic_support: "Diagnostic Support",
	WSA_Applications_item_card_remote_diagnostics: "Remote Diagnostics",
	WSA_Applications_item_card_service_training: "Service Training",
	WSA_Applications_item_card_technical_support: "Teknisk Support",
	Header_Label_Settings: "Inställningar",
	Assistant_Label_Bot: "Assistent",
	Assistant_first_message: "Hej, hur kan jag hjälpa dig idag?",
	Assistant_today_label: "I dag",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Gå med i chatten",
	Your_name_input_label: "Ditt namn",
	Code_input_label: "Koda",
	Start_btn_label: "Fortsätt",
	Change_info_label: "Ändra info",
	Change_name_label: "Byt namn",
	Change_code_label: "Ändra kod",
	assistant_ask_name_message: "Innan vi börjar, får jag veta vad du heter?",
	assistant_ask_code_message: "Ange din kod för att gå med i sessionen",
	assistant_ask_change_message: "Välj det objekt du vill ändra",
	assistant_ask_change_name_message: "Vänligen skriv ditt namn",
	assistant_ask_change_code_message: "Vänligen skriv kod",
	assistant_ask_change_action_message: "Välj åtgärdstyp",
	assistant__confirm_message: "Jag har fått din information: \n Din kod: [code] \n Ditt namn: [name] \n Vill du fortsätta?",
	Assistant_Welcome_Bot: "Välkommen till Tessa Bot!",
	End_meeting_Warning: "Är du säker på att avsluta mötet?. Mötet kan inte användas när det väl är över.",
	Leave_meeting_meesage_Warning: "Du är på väg att avsluta samtalet, klicka här för att fortsätta",
	Reject_Switch_modal_content_Voice: "Användaren tillåter inte att kameran sätts på",
	Change_action_label: "Ändra åtgärdstyp",
	BoschExpert_Label_Sender: "Bosch Agent",
	Retry_note_v2: "Stäng modalen och försök igen eller ändra koden",
	End_Channel_note: "Kanalen är slut!",
	Leave_Channel_note: "Teknikern har lämnat kanalen!",
	Join_Channel_note: "Teknikern har anslutit sig till kanalen!",
	Translation_is_available: "Översättning är tillgänglig!",
	Translation_is_available_detail: "Vi har upptäckt att ditt språk är [tech_language] och att Bosch Experts språk är [expert_language]. \n Vill du aktivera liveöversättning?",
	No_Label: "Nej",
	Minimize_Label: "Minimera",
	Maximize_Label: "Maximera",
	Close_Session_Label: "Stäng session",
	Popup_Title_Warning: "Varning",
	Show_Message_Label: "Klicka här för att se hela chatthistoriken för denna konversation",
	Hide_Message_label: "Klicka här för att dölja chatthistoriken",
	Close_Channel_Message_Question: "Vill du avsluta den här sessionen?",
	Channel_noti_joined_message: "[name] har gått med i kanalen.",
	Click_to_view_history_message: "Klicka här för att se chatthistoriken",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "På håll",
	Busy_Label: "Upptagen",
	OK_Label: "OK",
	Channel_Notification: "Kanalavisering",
	BE_Leave: "Det ser ut som att Bosch Agent har varit offline ett tag. Du kan fortsätta att vänta eller lämna den här konversationen.",
	Chat_LoadingChat_Message: "Laddar chatt.....",
	Popup_Title_Error: "Oj, något gick fel!"
};