export const TRANSLATIONS_PL = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Nowy kanał",
	Start_New_Question: "Jak rozpocząć nowy kanał prywatny?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kod",
	Channel_List: "Lista kanałów",
	Chat_Label: "Czat",
	Call_Label: "Rozmowa telefoniczna",
	Video_Label: "Wideo",
	Share_Label: "Udostępnij",
	Stop_Channel_Label: "Zatrzymaj kanał",
	Contact_Details_Label: "Dane kontaktowe",
	Request_Details_Label: "Szczegóły zamówienia",
	Attendees_Label: "Uczestnicy",
	Customer_Label: "Klient",
	Bosch_Expert_Label: "Specjalista Bosch",
	Type_Your_Message_Label: "Wpisz swoją wiadomość",
	Stop_Channel_Question: "Czy chcesz zamknąć kanał?",
	Yes_Label: "Tak",
	Cancel_Label: "Anuluj",
	Start_New_Video_Session: "Rozpocznij nową sesję wideo",
	Without_SMS_Label: "Bez SMS",
	Send_SMS_Label: "Wyślij SMS",
	Send_Invite_Label: "Wyślij zaproszenie",
	Log_Out_Question: "Czy chcesz się wylogować?",
	Welcome_Label: "Witamy w Private Channel Solution",
	Login_Label: "Zaloguj się",
	Login_Is_Required_Label: "Logowanie jest wymagane do uruchomienia kanału",
	Account_Permission_Label: "To konto nie ma uprawnień do uruchomienia kanału",
	Start_Label: "Start",
	Noted_Recommend: "Ze względu na politykę firmy Bosch dotyczącą korzystania z przeglądarki Firefox zalecamy korzystanie z innej przeglądarki, aby uzyskać najlepsze rezultaty z użytkowania z aplikacji Private Channel Solution. Zalecane przeglądarki: Microsoft Edge i Google Chrome",
	Available_Languages_Label: "Dostępne języki",
	Can_Not_Create_Meeting_Title: "Nie można utworzyć nowego kanału prywatnego",
	Can_Not_Create_Meeting_Content: "Jesteś nadal w aktywnym kanale prywatnym. Zakończ bieżący kanał przed rozpoczęciem nowego.",
	Leave_meeting_Warning: "Opuść kanał prywatny lub zakończ kanał dla wszystkich. Po zakończeniu kanału nie można z niego korzystać.",
	Leave_Lable: "Opuść",
	End_Lable: "Koniec",
	Meeting_Ended: "Spotkanie zostało zakończone",
	Start_Channel_Session: "Rozpocznij sesję kanału",
	Start_Channel_Session_Content: "Mechanik używa obecnie innego Ticket ID:",
	Join_Lable: "Dołącz",
	Join_Meeting_Label: "Dołącz do kanału",
	Meeting_Information_Label: "Informacje o kanale",
	Meeting_ID_Label: "ID kanału",
	Translate_Option_Title: "Opcja tłumaczenia",
	Translate_Mode_Label: "Tryb tłumaczenia:",
	From_Label: "Z:",
	To_Label: "Na:",
	Chat_Room_Label: "Kanał prywatny",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Jak chcesz udostępnić zaproszenie do kanału prywatnego?",
	Invite_To_Visual_Question: "Jak chcesz udostępnić zaproszenie do Visual Connect?",
	Invite_Sms_Question: "Wyślij zaproszenie SMS-em",
	Invite_To_Chat_By_Ticket_ID: "Wyślij zaproszenie przez Ticket ID",
	Invite_Email_Question: "Wyślij zaproszenie e-mailem",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Zastosowanie",
	WSA_Remote_Diagnostics_label: "Zdalna Diagnoza",
	WSA_Service_Training_label: "Szkolenia w zakresie serwisowania",
	WSA_Enter_website_label: "Wejdź na stronę internetową",
	WSA_Slogan_label: "Technologia bliżej nas",
	WSA_contact_info_label: "Ogólne dane kontaktowe",
	WSA_PSIRT_label: "Bezpieczeństwo produktu (PSIRT)",
	WSA_licences_label: "Innowacje, patenty i licencje",
	WSA_purchasing_label: "Zakupy i logistyka",
	WSA_copyright_label: "© Robert Bosch GmbH, wszystkie prawa zastrzeżone",
	WSA_copyright_information_label: "Informacje o firmie",
	WSA_copyright_notice_label: "Nota prawna",
	WSA_copyright_protectionnotice_label: "Informacja o ochronie danych",
	WSA_copyright_terms_label: "Zasady i warunki",
	WSA_copyright_settings_label: "Ustawienia prywatności",
	Infor_CorporateInformation_Description: "Odpowiedzialność za strony internetowe firmy Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Informacja o ochronie danych dla usługi Zdalna Diagnoza Bosch",
	Infor_LegalNotice_Description: "Dla użytkowników stron internetowych firmy Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Temat",
	Make_panel: "Marka",
	Model_pane: "Model",
	Engine_code_panel: "Kod silnika",
	Year_panel: "Rok",
	Vin_panel: "Numer VIN",
	Plate_panel: "Nr rejestracyjny",
	Hardware_panel: "Sprzęt",
	ar_mode_warning: "Twoje urządzenie nie jest dostępne dla AR",
	Write_something: "Napisz coś, aby rozpocząć czat",
	Session_title: "Sesja",
	Live_Translation: "Tłumaczenie na żywo",
	Enabled_panel: "Dostępne",
	Joined_note: "Dołączyłeś do sesji...",
	Today_panel: "Dzisiaj",
	Read_panel: "Odczytane",
	Sent_panel: "Wysłane",
	Dis_translation: "Wyłącz tłumaczenie",
	En_translation: "Włącz tłumaczenie",
	Terminated_Channel_note: "Specjalista Bosch zamknął kanał!",
	Not_start_note: "nie został jeszcze uruchomiony!",
	Retry_note: "Kliknij \"Spróbuj ponownie\", aby ponowić próbę lub zamknij to okno, aby wyjść.",
	Retry_panel: "Spróbuj ponownie",
	Thanks_note: "Dziękujemy za skorzystanie z serwisu technicznego Bosch. Bardzo się cieszymy, że mogliśmy dziś pomóc. Jeśli będziesz potrzebować pomocy, skontaktuj się z nami ponownie. Życzymy miłego dnia.",
	Continue_panel: "Włącz tłumaczenie na żywo",
	En_live_translate: "Kontynuuj",
	Translate_from: "Tłumacz z",
	Translate_to: "Tłumacz na",
	Random_ID: "Losowy identyfikator",
	Anonymous: "Anonimowy",
	Generated_ID: "Wygenerowano losowy identyfikator!",
	Your_ID: "Twój losowy identyfikator to:",
	Public_Meeting_ID_Label: "Publiczny identyfikator spotkania:",
	SelectContactPersonModal_Title: "Z kim chcesz porozmawiać?",
	SelectContactPersonDropDown_Title: "Osoba kontaktowa",
	SelectContactPersonDropDown_Error_Message: "Ten użytkownik nie ma identyfikatora CIAM (konta użytkownika CDM), wybierz inną osobę kontaktową, aby kontynuować ...",
	TicketCreator__Title: "(twórca zgłoszenia)",
	Wsa_status_title: "Status WSA",
	Close_panel: "Zamknij",
	OCR_Detected_title: "Wykryto optyczne rozpoznawanie znaków:",
	Voice_Call_Label: "Połączenie głosowe",
	Invite_To_Voice_Call_Question: "Jak chcesz udostępnić zaproszenie do połączenia głosowego?",
	AR_mode: "Tryb AR",
	Switch_modal_title: "Przełącz",
	Switch_modal_content_Video: "Przełączenie na połączenie wideo zakończone sukcesem!",
	Switch_modal_content_Voice: "Przełączenie na połączenie głosowe zakończone sukcesem!",
	Video_Call_Label: "Połączenie wideo",
	Functionality_Label: "Funkcjonalność",
	Press_Chatting_Button_Label: "Naciśnij przycisk czatu, aby rozpocząć rozmowę",
	WSA_Applications_label_full: "Aplikacje serwisowe warsztatu",
	WSA_Applications_item_card: "Wejdź do aplikacji",
	WSA_Applications_item_card_diagnostic_support: "Wsparcie diagnostyczne",
	WSA_Applications_item_card_remote_diagnostics: "Diagnostyka zdalna",
	WSA_Applications_item_card_service_training: "Szkolenie serwisowe",
	WSA_Applications_item_card_technical_support: "Wsparcie techniczne",
	Header_Label_Settings: "Ustawienia",
	Assistant_Label_Bot: "Asystent",
	Assistant_first_message: "Witaj, jak mogę Ci dzisiaj pomóc?",
	Assistant_today_label: "Dzisiaj",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Dołącz do czatu",
	Your_name_input_label: "Twoje imię",
	Code_input_label: "Kod",
	Start_btn_label: "Zaczynajmy",
	Change_info_label: "Zmień informacje",
	Change_name_label: "Zmień imię",
	Change_code_label: "Zmień kod",
	assistant_ask_name_message: "Zanim zaczniemy, mogę poznać Twoje imię?",
	assistant_ask_code_message: "Wprowadź kod, aby dołączyć do sesji",
	assistant_ask_change_message: "Wybierz element, który chcesz zmienić",
	assistant_ask_change_name_message: "Wpisz swoje imię",
	assistant_ask_change_code_message: "Wpisz kod",
	assistant_ask_change_action_message: "Wybierz rodzaj działania",
	assistant__confirm_message: "Otrzymałem Twoje informacje: \n Twój kod: [code] \n Twoje imię: [name] \n Czy chcesz kontynuować?",
	Assistant_Welcome_Bot: "Witaj w Tessa Bot!",
	End_meeting_Warning: "Czy na pewno chcesz zakończyć spotkanie? Po zakończeniu nie będzie już można kontynuować spotkania.",
	Leave_meeting_meesage_Warning: "Zaraz zakończysz połączenie, kliknij tutaj, aby kontynuować",
	Reject_Switch_modal_content_Voice: "Użytkownik nie zezwala na włączenie kamery",
	Change_action_label: "Zmień rodzaj działania",
	BoschExpert_Label_Sender: "Agent Bosch",
	Retry_note_v2: "Zamknij okno modalne, a następnie spróbuj ponownie lub zmień kod",
	End_Channel_note: "Kanał został zakończony!",
	Leave_Channel_note: "Technik opuścił kanał!",
	Join_Channel_note: "Technik dołączył do kanału!",
	Translation_is_available: "Dostępne jest tłumaczenie!",
	Translation_is_available_detail: "Wykryliśmy, że Twoim językiem jest [tech_language], a językiem ekspertów Bosch jest [expert_language]. \n Czy chcesz włączyć tłumaczenie na żywo?",
	No_Label: "Nie",
	Minimize_Label: "Zminimalizuj",
	Maximize_Label: "Zmaksymalizuj",
	Close_Session_Label: "Zakończ sesję",
	Popup_Title_Warning: "Ostrzeżenie",
	Show_Message_Label: "Kliknij tutaj, aby wyświetlić pełną historię czatu tej rozmowy",
	Hide_Message_label: "Kliknij tutaj, aby ukryć historię czatu",
	Close_Channel_Message_Question: "Czy chcesz zakończyć tę sesję?",
	Channel_noti_joined_message: "[name] dołączył do kanału.",
	Click_to_view_history_message: "Kliknij tutaj, aby wyświetlić historię czatu",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "Wstrzymane",
	Busy_Label: "Zajęty",
	OK_Label: "OK",
	Channel_Notification: "Powiadomienie o kanale",
	BE_Leave: "Wygląda na to, że agent Bosch był offline przez jakiś czas. Możesz kontynuować czekanie lub opuścić rozmowę.",
	Chat_LoadingChat_Message: "Ładowanie czatu.....",
	Popup_Title_Error: "Ups, coś poszło nie tak!"
};