export const TRANSLATIONS_PT = {
	Logo_Title_Label: "Conversa privada",
	Service_Assist_Label: "Assistente de serviço",
	Start_New_LabelBtn: "Nova comunicação",
	Start_New_Question: "Gostaria de iniciar uma nova comunicação",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Código",
	Channel_List: "Lista de comunicações",
	Chat_Label: "Chat",
	Call_Label: "Chamada",
	Video_Label: "Video",
	Share_Label: "Partilha",
	Stop_Channel_Label: "Parar comunicação",
	Contact_Details_Label: "Detalhes do contato",
	Request_Details_Label: "Solicitar detalhes",
	Attendees_Label: "Participantes",
	Customer_Label: "Cliente",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Escreva a sua mensagem",
	Stop_Channel_Question: "Quer terminar a conversação?",
	Yes_Label: "Sim",
	Cancel_Label: "Terminar",
	Start_New_Video_Session: "Iniciar nova conversa por video",
	Without_SMS_Label: "Sem SMS",
	Send_SMS_Label: "Enviar SMS",
	Send_Invite_Label: "Enviar convite",
	Log_Out_Question: "Quer sair da sessão?",
	Welcome_Label: "Bem vindo à comunicação privada",
	Login_Label: "Iniciar nova conversa por video",
	Login_Is_Required_Label: "Necessário uma ligação para iniciar a conversação",
	Account_Permission_Label: "Esta conta não têm permissão para iniciar uma conversa",
	Start_Label: "Iniciar",
	Noted_Recommend: "Devido à politica da Bosch na utilização do Firefox, recomendamos a utilização de um explorador diferente para uma melhor experiência durante a conversação privada. Exploradores recomendados: Microsoft Edge e Google Chrome",
	Available_Languages_Label: "Idiomas disponiveis",
	Can_Not_Create_Meeting_Title: "Não é possível iniciar uma nova sessão privada",
	Can_Not_Create_Meeting_Content: "Você ainda se encontra num conversação, para poder iniciar uma nova conversação feche a atual.",
	Leave_meeting_Warning: "Deixe a conversa particular ou termine a sessão para todos. Assim que terminar a sessão esta não poder ser mais utilizada.",
	Leave_Lable: "Sair",
	End_Lable: "Fim",
	Meeting_Ended: "A conversação foi terminada",
	Start_Channel_Session: "Iniciar a sessão",
	Start_Channel_Session_Content: "O técnico utiliza atualmente um outro Ticket ID:",
	Join_Lable: "Unir-se",
	Join_Meeting_Label: "Unir-se à comunicação",
	Meeting_Information_Label: "Informação da comunicação",
	Meeting_ID_Label: "Canal ID",
	Translate_Option_Title: "Opção de tradução",
	Translate_Mode_Label: "Modo de tradução:",
	From_Label: "De:",
	To_Label: "Para:",
	Chat_Room_Label: "Canal Privado",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Como pretende enviar o convite para a ligação da conversa privada?",
	Invite_To_Visual_Question: "Como pretende enviar o convite para a ligação do Visual Connect ?",
	Invite_Sms_Question: "Enviar convite por SMS",
	Invite_To_Chat_By_Ticket_ID: "Enviar convite por Ticket ID",
	Invite_Email_Question: "Enviar convite por Email",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Aplicações",
	WSA_Remote_Diagnostics_label: "Diagnóstico Remoto",
	WSA_Service_Training_label: "Formação",
	WSA_Enter_website_label: "Entre no website",
	WSA_Slogan_label: "Inovação para a vida",
	WSA_contact_info_label: "Informação geral do contato",
	WSA_PSIRT_label: "Segurança do produto (PSIRT)",
	WSA_licences_label: "Inovações, patentes e licenças",
	WSA_purchasing_label: "Compras e logística",
	WSA_copyright_label: "© Robert Bosch GmbH, todos os direitos reservados",
	WSA_copyright_information_label: "Informação corporativa",
	WSA_copyright_notice_label: "Aviso legal",
	WSA_copyright_protectionnotice_label: "Cláusula de privacidade",
	WSA_copyright_terms_label: "Termos e condições",
	WSA_copyright_settings_label: "Ajustes de privacidade",
	Infor_CorporateInformation_Description: "Responsável pela página da Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Cláusula de privacidade do serviço de Diagnóstico Remoto da Bosch",
	Infor_LegalNotice_Description: "Para utilizadores da página web da Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Tema",
	Make_panel: "Marca",
	Model_pane: "Modelo",
	Engine_code_panel: "Código do motor",
	Year_panel: "Ano",
	Vin_panel: "VIN",
	Plate_panel: "Matrícula",
	Hardware_panel: "Hardware",
	ar_mode_warning: "O seu equipamento não permite AR",
	Write_something: "Escreva algo para iniciar a conversação",
	Session_title: "Sessão",
	Live_Translation: "Tradução ao vivo",
	Enabled_panel: "Dísponivel",
	Joined_note: "Juntou-se à sessão…",
	Today_panel: "Hoje",
	Read_panel: "Lido",
	Sent_panel: "Enviado",
	Dis_translation: "Nova comunicação",
	En_translation: "Ativar a tradução",
	Terminated_Channel_note: "O técnico da Bosch terminou a sessão",
	Not_start_note: "ainda não inciado!",
	Retry_note: "Por favor, selecione novamente \"repetir\" para voltar a repetir ou feche a janela.",
	Retry_panel: "Repetir",
	Thanks_note: "Obrigado por usar o Serviço Técnico da Bosch. Estamos muito agradecidos de ter tido a oportunidade de hoje o ter podido ajudar. Entre em contato connosco novamente se necessitar de ajuda. Desejamos um bom dia!",
	Continue_panel: "Ativar a tradução ao vivo",
	En_live_translate: "Continuar",
	Translate_from: "Traduzir de",
	Translate_to: "Traduzir para",
	Random_ID: "ID aleatório",
	Anonymous: "Anônima",
	Generated_ID: "ID aleatório foi gerado!",
	Your_ID: "Seu ID aleatório é:",
	Public_Meeting_ID_Label: "ID da Reunião Pública:",
	SelectContactPersonModal_Title: "Com quem você gostaria de conversar?",
	SelectContactPersonDropDown_Title: "Pessoa de Contato",
	SelectContactPersonDropDown_Error_Message: "Este usuário não tem um id ciam (conta de usuário para CDM), selecione outra pessoa de contato para continuar ...",
	TicketCreator__Title: "(criador do ticket)",
	Wsa_status_title: "Status WSA",
	Close_panel: "Fechar",
	OCR_Detected_title: "Reconhecimento Óptico de Caracteres Detectado:",
	Voice_Call_Label: "Chamada de Voz",
	Invite_To_Voice_Call_Question: "Como você gostaria de compartilhar o convite para a Chamada de Voz?",
	AR_mode: "MODO AR",
	Switch_modal_title: "Trocar",
	Switch_modal_content_Video: "Troca para Chamada de Vídeo bem-sucedida!",
	Switch_modal_content_Voice: "Troca para Chamada de Voz bem-sucedida!",
	Video_Call_Label: "Chamada de Vídeo",
	Functionality_Label: "Funcionalidade",
	Press_Chatting_Button_Label: "Pressione o botão de chat para iniciar a sala de chat",
	WSA_Applications_label_full: "Aplicações de Serviço do Workshop",
	WSA_Applications_item_card: "Inserir aplicação",
	WSA_Applications_item_card_diagnostic_support: "Suporte Diagnóstico",
	WSA_Applications_item_card_remote_diagnostics: "Diagnóstico Remoto",
	WSA_Applications_item_card_service_training: "Treinamento de Serviço",
	WSA_Applications_item_card_technical_support: "Suporte Técnico",
	Header_Label_Settings: "Configurações",
	Assistant_Label_Bot: "Assistente",
	Assistant_first_message: "Olá, como posso ajudá-lo hoje?",
	Assistant_today_label: "Hoje",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Junte-se ao Chat",
	Your_name_input_label: "Seu nome",
	Code_input_label: "Código",
	Start_btn_label: "Vamos lá",
	Change_info_label: "Mudar informação",
	Change_name_label: "Mudar Nome",
	Change_code_label: "Mudar Código",
	assistant_ask_name_message: "Antes de começarmos, posso saber seu nome, por favor?",
	assistant_ask_code_message: "Por favor, insira seu código para participar da sessão",
	assistant_ask_change_message: "Por favor, escolha o item que você deseja alterar",
	assistant_ask_change_name_message: "Por favor, digite seu nome",
	assistant_ask_change_code_message: "Por favor, digite o código",
	assistant_ask_change_action_message: "Por favor, selecione o tipo de ação",
	assistant__confirm_message: "Recebi suas informações: \n Seu código: [code] \n Seu nome: [name] \n Você gostaria de continuar?",
	Assistant_Welcome_Bot: "Bem-vindo ao Tessa Bot!",
	End_meeting_Warning: "Você tem certeza de que deseja encerrar a reunião? A reunião não pode ser usada após o encerramento.",
	Leave_meeting_meesage_Warning: "Você está prestes a encerrar a chamada, clique aqui para continuar",
	Reject_Switch_modal_content_Voice: "O usuário não permite que a câmera seja ativada",
	Change_action_label: "Mudar Tipo de Ação",
	BoschExpert_Label_Sender: "Agente Bosch",
	Retry_note_v2: "Por favor, feche o modal e tente novamente ou altere o código",
	End_Channel_note: "O canal foi encerrado!",
	Leave_Channel_note: "O técnico saiu do canal!",
	Join_Channel_note: "O técnico se juntou ao canal!",
	Translation_is_available: "A tradução está disponível!",
	Translation_is_available_detail: "Detectamos que seu idioma é [tech_language] e que o idioma dos especialistas Bosch é [expert_language]. \n Você gostaria de ativar a tradução ao vivo?",
	No_Label: "Não",
	Minimize_Label: "Minimizar",
	Maximize_Label: "Maximizar",
	Close_Session_Label: "Fechar Sessão",
	Popup_Title_Warning: "Aviso",
	Show_Message_Label: "Clique aqui para visualizar o histórico completo de chat desta conversa",
	Hide_Message_label: "Clique aqui para ocultar o histórico de chat",
	Close_Channel_Message_Question: "Você deseja fechar esta sessão?",
	Channel_noti_joined_message: "[name] se juntou ao canal.",
	Click_to_view_history_message: "Clique aqui para visualizar o histórico de chat",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "Em Espera",
	Busy_Label: "Ocupado",
	OK_Label: "OK",
	Channel_Notification: "Notificação do Canal",
	BE_Leave: "Parece que o agente Bosch está offline há um tempo. Você pode continuar esperando ou sair desta conversa.",
	Chat_LoadingChat_Message: "Carregando chat.....",
	Popup_Title_Error: "Ops, algo deu errado!"
};