export const TRANSLATIONS_DE = {
	Logo_Title_Label: "Private Unterhaltung",
	Service_Assist_Label: "Service Assistent",
	Start_New_LabelBtn: "Neue Unterhaltung",
	Start_New_Question: "Wie wollen Sie ihre neue Unterhaltung starten?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Code",
	Channel_List: "Unterhaltung Liste",
	Chat_Label: "Chat",
	Call_Label: "Anruf",
	Video_Label: "Video",
	Share_Label: "teilen",
	Stop_Channel_Label: "Unterhaltung stoppen",
	Contact_Details_Label: "Kontaktdaten",
	Request_Details_Label: "Service Details",
	Attendees_Label: "Teilnehmer",
	Customer_Label: "Kunde",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Schreiben Sie eine Nachricht",
	Stop_Channel_Question: "Möchten Sie die Unterhaltung schließen?",
	Yes_Label: "Ja",
	Cancel_Label: "Abbrechen",
	Start_New_Video_Session: "Neue Video Unterhaltung starten",
	Without_SMS_Label: "Ohne SMS",
	Send_SMS_Label: "SMS senden",
	Send_Invite_Label: "Einladung senden",
	Log_Out_Question: "Möchten Sie sicht ausloggen?",
	Welcome_Label: "Willkomen zu der privaten Unterhaltung",
	Login_Label: "Login",
	Login_Is_Required_Label: "Login ist notwendig um eine Unterhatlung zu starten",
	Account_Permission_Label: "Dieser Account hat keine Berechtigung eine Unterhaltung zu starten",
	Start_Label: "Start",
	Noted_Recommend: "Aufgrund der Richtlinien von Bosch zur Verwendung von Firefox empfehlen wir Ihnen, einen anderen Browser zu verwenden, um das beste Nutzererlebnis für die private Unterhaltung zu erzielen. Empfohlene Browser: Microsoft Edge und Google Chrome",
	Available_Languages_Label: "vorhandene Sprachen",
	Can_Not_Create_Meeting_Title: "Nicht möglich eine neue private Unterhaltung zu starten",
	Can_Not_Create_Meeting_Content: "Sie sind noch in einer aktiven Unterhaltung, bitte schließen Sie diese um eine neue zu starten.",
	Leave_meeting_Warning: "Verlassen Sie die privaten Unterhaltung oder beenden Sie die Unterhaltung für alle. Sobald die Unterhaltung beendet ist, kann sie nicht mehr genutzt werden.",
	Leave_Lable: "Verlassen",
	End_Lable: "Ende",
	Meeting_Ended: "Die Unterhaltung wurde beendet",
	Start_Channel_Session: "Unterhaltung starten",
	Start_Channel_Session_Content: "Der Mechaniker benutzt aktuell eine andere Ticket ID",
	Join_Lable: "Beitreten",
	Join_Meeting_Label: "Unterhaltung beitreten",
	Meeting_Information_Label: "Unterhaltung Details",
	Meeting_ID_Label: "Unterhaltungs ID",
	Translate_Option_Title: "Übersetzungsoptionen",
	Translate_Mode_Label: "Übersetzungs Modus",
	From_Label: "Von:",
	To_Label: "An:",
	Chat_Room_Label: "private Unterhaltung",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Wie wollen Sie die Einladung für die private Unterhaltung versenden?",
	Invite_To_Visual_Question: "Wie wollen Sie die Einladung für Visual Connect​ versenden?",
	Invite_Sms_Question: "Einladung per SMS senden",
	Invite_To_Chat_By_Ticket_ID: "Einladung per Ticket ID senden",
	Invite_Email_Question: "Einladung per E-Mail senden",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Anwendungen",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Webseite betreten",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "Allgemeine Kontaktdaten",
	WSA_PSIRT_label: "Product security (PSIRT)",
	WSA_licences_label: "Innovationen, Patente und Lizenzen",
	WSA_purchasing_label: "Einkauf und Logistik",
	WSA_copyright_label: "© Robert Bosch GmbH, alle Rechte vorbehalten",
	WSA_copyright_information_label: "Unternehmensinformationen",
	WSA_copyright_notice_label: "Rechtliche Hinweise",
	WSA_copyright_protectionnotice_label: "Datenschutzhinweis",
	WSA_copyright_terms_label: "Allgemeine Geschäftsbedingungen",
	WSA_copyright_settings_label: "Datenschutzeinstellungen",
	Infor_CorporateInformation_Description: "Verantwortlich für die Webseiten der Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Datenschutzhinweise für den Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "Für Nutzer von Webseiten der Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Thema",
	Make_panel: "Marke",
	Model_pane: "Model",
	Engine_code_panel: "Motorcode",
	Year_panel: "Jahr",
	Vin_panel: "VIN",
	Plate_panel: "Kennzeichen",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Ihr Gerät ist nicht verfügbar für AR",
	Write_something: "Schreiben Sie etwas um die Unterhaltung zu starten",
	Session_title: "Sitzung",
	Live_Translation: "Live Übersetzung",
	Enabled_panel: "Vorhanden",
	Joined_note: "Sie sind der Sitzung beigetreten",
	Today_panel: "Heute",
	Read_panel: "Gelesen",
	Sent_panel: "Gesendet",
	Dis_translation: "Übersetzung deaktivieren",
	En_translation: "Übersetzung aktivieren",
	Terminated_Channel_note: "Der Bosch Experte hat die Unterhatlung beendet",
	Not_start_note: "noch nicht gestartet",
	Retry_note: "Bitte drücken Sie \"Wiederholen\" um es erneut zu versuchen oder schließen Sie das Fenster.",
	Retry_panel: "Wiederholen",
	Thanks_note: "Vielen Dank, dass Sie den Technischen Service von Bosch in Anspruch genommen haben. Ich freue mich sehr, dass wir heute die Gelegenheit hatten, Ihnen zu helfen. Bitte kontaktieren Sie uns erneut, wenn Sie Hilfe benötigen. Ich wünsche Ihnen einen schönen Tag.",
	Continue_panel: "Weiter",
	En_live_translate: "Live Übersetzung aktivieren",
	Translate_from: "Übersetzen von",
	Translate_to: "Übersetzen nach",
	Random_ID: "Zufällige ID",
	Anonymous: "Anonym",
	Generated_ID: "Zufällige ID wurde generiert!",
	Your_ID: "Ihre zufällige ID ist:",
	Public_Meeting_ID_Label: "Öffentliche ID:",
	SelectContactPersonModal_Title: "Mit wem möchten Sie chatten?",
	SelectContactPersonDropDown_Title: "Kontaktperson",
	SelectContactPersonDropDown_Error_Message: "Dieser Benutzer hat keine ciam-ID (Benutzerkonto für CDM). Wählen Sie eine andere Kontaktperson, um fortzufahren ...",
	TicketCreator__Title: "(Ticket-Ersteller)",
	Wsa_status_title: "WSA-Status",
	Close_panel: "Schließen",
	OCR_Detected_title: "Optische Zeichenerkennung erkannt:",
	Voice_Call_Label: "Sprachanruf",
	Invite_To_Voice_Call_Question: "Wie möchten Sie die Einladung zum Sprachanruf teilen?",
	AR_mode: "AR-MODUS",
	Switch_modal_title: "Umschalten",
	Switch_modal_content_Video: "Erfolgreich zu Videoanruf gewechselt!",
	Switch_modal_content_Voice: "Erfolgreich zu Sprachanruf gewechselt!",
	Video_Call_Label: "Videoanruf",
	Functionality_Label: "Funktionalität",
	Press_Chatting_Button_Label: "Drücken Sie die Chat-Taste, um den Chat zu starten",
	WSA_Applications_label_full: "Workshop-Service Anwendungen",
	WSA_Applications_item_card: "Anwendung eingeben",
	WSA_Applications_item_card_diagnostic_support: "Diagnostische Unterstützung",
	WSA_Applications_item_card_remote_diagnostics: "Remote Diagnostics",
	WSA_Applications_item_card_service_training: "Service Training",
	WSA_Applications_item_card_technical_support: "Technischer Support",
	Header_Label_Settings: "Einstellungen",
	Assistant_Label_Bot: "Assistent",
	Assistant_first_message: "Hallo, wie kann ich Ihnen heute helfen?",
	Assistant_today_label: "Heute",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Chat beitreten",
	Your_name_input_label: "Ihr Name",
	Code_input_label: "Code",
	Start_btn_label: "Lass uns gehen",
	Change_info_label: "Informationen ändern",
	Change_name_label: "Name ändern",
	Change_code_label: "Code ändern",
	assistant_ask_name_message: "Bevor wir anfangen, darf ich bitte Ihren Namen erfahren?",
	assistant_ask_code_message: "Bitte geben Sie Ihren Code ein, um an der Sitzung teilzunehmen",
	assistant_ask_change_message: "Bitte wählen Sie den Punkt, den Sie ändern möchten",
	assistant_ask_change_name_message: "Bitte geben Sie Ihren Namen ein",
	assistant_ask_change_code_message: "Bitte geben Sie den Code ein",
	assistant_ask_change_action_message: "Bitte wählen Sie den Aktionstyp",
	assistant__confirm_message: "Ich habe Ihre Informationen erhalten: \n Ihr Code: [code] \n Ihr Name: [name] \n Möchten Sie fortfahren?",
	Assistant_Welcome_Bot: "Willkommen bei Tessa Bot!",
	End_meeting_Warning: "Sind Sie sicher, dass Sie das Meeting beenden möchten? Das Meeting kann nicht mehr verwendet werden, sobald es beendet ist.",
	Leave_meeting_meesage_Warning: "Sie sind dabei, den Anruf zu beenden. Klicken Sie hier, um fortzufahren.",
	Reject_Switch_modal_content_Voice: "Benutzer erlaubt nicht, die Kamera einzuschalten",
	Change_action_label: "Aktionstyp ändern",
	BoschExpert_Label_Sender: "Bosch-Agent",
	Retry_note_v2: "Bitte schließen Sie das Modal und versuchen Sie es erneut oder ändern Sie den Code",
	End_Channel_note: "Der Kanal ist beendet!",
	Leave_Channel_note: "Der Techniker hat den Kanal verlassen!",
	Join_Channel_note: "Der Techniker hat den Kanal betreten!",
	Translation_is_available: "Übersetzung ist verfügbar!",
	Translation_is_available_detail: "Wir haben festgestellt, dass Ihre Sprache [tech_language] ist und dass die Sprache der Bosch-Experten [expert_language] ist. \n Möchten Sie die Live-Übersetzung aktivieren?",
	No_Label: "Nein",
	Minimize_Label: "Minimieren",
	Maximize_Label: "Maximieren",
	Close_Session_Label: "Sitzung schließen",
	Popup_Title_Warning: "Warnung",
	Show_Message_Label: "Klicken Sie hier, um die vollständige Chat-Historie dieses Gesprächs anzuzeigen",
	Hide_Message_label: "Klicken Sie hier, um die Chat-Historie auszublenden",
	Close_Channel_Message_Question: "Möchten Sie diese Sitzung schließen?",
	Channel_noti_joined_message: "[name] ist dem Kanal beigetreten.",
	Click_to_view_history_message: "Klicken Sie hier, um die Chat-Historie anzuzeigen",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "In Warteschleife",
	Busy_Label: "Besetzt",
	OK_Label: "OK",
	Channel_Notification: "Kanalbenachrichtigung",
	BE_Leave: "Es scheint, dass der Bosch-Agent eine Weile offline war. Sie können weiterhin warten oder dieses Gespräch verlassen.",
	Chat_LoadingChat_Message: "Chat wird geladen.....",
	Popup_Title_Error: "Ups, etwas ist schiefgelaufen!"
};