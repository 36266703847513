import React, { useEffect, useState } from 'react';
import { MessageAttachment } from 'amazon-chime-sdk-component-library-react';
import { AttachmentService } from '../../services/AttachmentService';
import defaultImage from '../../assets/default-img.jpg';

interface AttachmentProps {
    fileKey: string,
    name: string,
    size: string,
    isImage: boolean
    isScanResult?: boolean;
}

const Attachment = ({ fileKey, name, size = "0", isImage, isScanResult }: AttachmentProps) => {
    const [url, setUrl] = useState(defaultImage);
    const [downUrl, setDownUrl] = useState("");

    const attachmentService = AttachmentService.getInstance();

    useEffect(() => {
        let downloadLink = `${process.env.REACT_APP_API_KEY_PCS}${isScanResult ? `/api/Document/DownloadFileScanResult/` : `/api/Document/DownloadFile/`}` + fileKey +
            `/${name}`;
        setDownUrl(downloadLink);
        async function getUrl() {
            const data = await attachmentService.getPresignURL(fileKey);
            setUrl(data);
        }
        getUrl();
    }, [fileKey]);


    return (
        <MessageAttachment renderImg={isImage} name={name} downloadUrl={downUrl} size={size} imgUrl={url} />
    );
};

export default Attachment;
