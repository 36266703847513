import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_DA } from "./da/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_NO } from "./no/translations";
import { TRANSLATIONS_SV } from "./sv/translations";
import { TRANSLATIONS_NL } from "./nl/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_CZ } from "./cz/translations";
import { TRANSLATIONS_SK } from "./sk/translations";
import { TRANSLATIONS_PL } from "./pl/translations";
import { TRANSLATIONS_LT } from "./lt/translations";
import { TRANSLATIONS_LV } from "./lv/translations";
import { TRANSLATIONS_ET } from "./et/translations";
import { TRANSLATIONS_NN } from "./nn/translations";
import { TRANSLATIONS_SW } from "./sw/translations";


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        resources: {
            "en": {
                translation: TRANSLATIONS_EN
            },
            "es": {
                translation: TRANSLATIONS_ES
            },
            "de": {
                translation: TRANSLATIONS_DE
            },
            "pt": {
                translation: TRANSLATIONS_PT
            },
            "da": {
                translation: TRANSLATIONS_DA
            },
            "fi": {
                translation: TRANSLATIONS_FI
            },
            "no": {
                translation: TRANSLATIONS_NO
            },
            "sv": {
                translation: TRANSLATIONS_SV
            },
            "nl": {
                translation: TRANSLATIONS_NL
            },
            "fr": {
                translation: TRANSLATIONS_FR
            },
            "it": {
                translation: TRANSLATIONS_IT
            },
            "cz": {
                translation: TRANSLATIONS_CZ
            },
            "sk": {
                translation: TRANSLATIONS_SK
            },
            "pl": {
                translation: TRANSLATIONS_PL
            },
            "lt": {
                translation: TRANSLATIONS_LT
            },
            "lv": {
                translation: TRANSLATIONS_LV
            },
            "et": {
                translation: TRANSLATIONS_ET
            },
            "nn": {
                translation: TRANSLATIONS_NN
            },
            "sw": {
                translation: TRANSLATIONS_SW
            }
        },
        parseMissingKeyHandler: function (key: string) {
            let str = key as keyof typeof TRANSLATIONS_EN;
            let ret = TRANSLATIONS_EN[str];
            if (!ret)
                ret = key;
            return ret;
        }
    });

export default i18n;